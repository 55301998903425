import React, { ReactNode } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core/';
import Color from 'color';
import classNames from 'classnames';

const defaultProps = {
  variant: 'default',
};

export type Props = {
  // variant を追加したらここも追加すること
  variant: 'default' | 'error' | 'secondary';
  children: ReactNode;
} & typeof defaultProps &
  ButtonBaseProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: 'background-color 0.5s, color 0.5s',
    },
    visible: {},
    // ボタン色ブルー
    default: {
      textAlign: 'center',
      borderRadius: 6.3,
      backgroundColor: '#0079ff',

      '&:disabled': {
        backgroundColor: '#D3D3D3',
      },
      '& span': {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
        color: '#FFF',
        fontSize: 13,
        fontWeight: 'normal',
        fontStyle: 'normal',
        letterSpacing: 0.78,
      },
      '&:hover': {
        backgroundColor: `${Color('#0079ff').darken(0.2)}`,
      },
    },
    error: {
      width: 210,
      height: 40,
      borderRadius: 20,
      border: 'solid 2px #ff0000',
      '& span': {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontFamily: 'HiraginoSans-W6',
        fontSize: 16,
        fontWeight: 'normal',
        fontStyle: 'normal',
        letterSpacing: 0.4,
        textAlign: 'center',
        color: '#ff0000',
      },
      '&:hover': {
        '& span': {
          color: `${Color('#ff0000').darken(0.2)}`,
        },
        borderColor: `${Color('#ff0000').darken(0.2)}`,
      },
    },
    // ボタン色黄色
    secondary: {
      textAlign: 'center',
      borderRadius: 6.3,
      backgroundColor: '#f7b90c',

      '&:disabled': {
        backgroundColor: '#D3D3D3',
      },
      '& span': {
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '8px',
        paddingBottom: '8px',
        color: '#FFF',
        fontSize: 13,
        fontWeight: 'normal',
        fontStyle: 'normal',
        letterSpacing: 0.78,
      },
      '&:hover': {
        backgroundColor: `${Color('#f7b90c').darken(0.1)}`,
      },
    },
    // variant class ボタンの種類をふやす場合は、この下にクラスを追加
  })
);

export default function MyButton(props: Props) {
  const classes = useStyles();
  const { variant, children, ...rest } = props;
  const buttonClass = classNames([classes.root, classes[variant]]);

  return (
    <ButtonBase
      className={buttonClass}
      focusVisibleClassName={classes.visible}
      focusRipple
      {...rest}
    >
      <span>{children}</span>
    </ButtonBase>
  );
}
