// ダウンロード対象ファイルの拡張子
export const DOWNLOAD_FILE_EXTENSIONS: string[] = [
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.doc',
  '.docx',
];

// デフォルト画像がサムネイルとして設定されているファイル
export const DISPLAY_FILENAME_EXTENSIONS: string[] = [
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.doc',
  '.docx',
  '.glb',
];

// アップロード対象外のファイルが選択されたときのメッセージ
export const INVALID_FILE_TYPE_MESSAGE =
  'pdf, jpeg, jpg, png, gif, glb, Word, Excel, Power Point以外は投稿できません';
