export const getThumbnail = (fileUrl: string, fileThumbnail: string) => {
  const getFileType = (url: string) => {
    const fileExtensions: Record<string, string> = {
      '.xls': 'excel',
      '.xlsx': 'excel',
      '.doc': 'word',
      '.docx': 'word',
      '.ppt': 'power_point',
      '.pptx': 'power_point',
      '.glb': 'three_d_file',
    };

    const extension = Object.keys(fileExtensions).find((ext) =>
      url.includes(ext)
    );
    return extension ? fileExtensions[extension] : null;
  };

  const getFileThumbnailPath = (type: string) =>
    `/assets/img/appointments/data_files/${type}_thumb.png`;

  const fileType = getFileType(fileUrl);
  return fileType ? getFileThumbnailPath(fileType) : fileThumbnail;
};
