import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { loginUser } from 'features/user/userSlice';
import { fetchDataFiles } from '../dataFileSlice';
import { fetchCatalogs } from '../../../features/catalog/catalogSlice';
import * as Sentry from '@sentry/browser';
import File from '../../../components/pages/Files';

type Props = {
  children?: ReactNode;
};

export default function FileWithRedux(props: Props) {
  const dispatch = useDispatch<any>();

  // TODO: as any良くない
  const dataFiles = useSelector(
    (state: RootState) => state.dataFile.dataFiles
  ) as any;

  const catalogs = useSelector(
    (state: RootState) => state.catalog.catalogs
  ) as any;

  const slideOpen = useSelector(
    (state: RootState) => state.user.slideOpen
  ) as any;

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchDataFiles('appointments'));
        dispatch(loginUser());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  const onChangeFileType = (fileType: string) => {
    dispatch(fetchDataFiles(fileType));
  };

  const handleFetchCatalogs = () => {
    dispatch(fetchCatalogs());
  };

  return (
    <>
      <File
        dataFiles={dataFiles}
        catalogs={catalogs}
        handleFileType={(filetype) => onChangeFileType(filetype)}
        handleCatalogs={handleFetchCatalogs}
        slideOpen={slideOpen}
      />
    </>
  );
}
