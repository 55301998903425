import axios from 'axios';

export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v1`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': process.env.REACT_APP_API_KEY,
  },
  withCredentials: true,
});

export default apiClient;
