import React, { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import A from '../../atoms/A';

const defaultProps = {};

interface StateType {
  errorStatus?: number;
}

type Props = {
  children?: ReactNode;
} & typeof defaultProps;

export default function Error(props: Props) {
  const location = useLocation();
  const [state] = useState<StateType>(location.state);

  // routingエラー
  let errorMessage =
    'ご指定の画面が見つかりませんでした。恐れ入りますが、ホーム画面から操作をお願いします。';
  if (state && state.errorStatus === 500) {
    // 500エラー
    errorMessage =
      '予期せぬエラーが発生しました。恐れ入りますが、ホーム画面から操作をお願いします。';
  } else if (state && state.errorStatus === 404) {
    // 404エラー
    errorMessage =
      '指定したデータが見つかりませんでした。恐れ入りますが、ホーム画面から操作をお願いします。';
  }
  return (
    <div className="Error">
      <h1 className="Error__title -icon-caution">エラーが発生しました</h1>
      <img
        className="Error__image"
        src="/assets/img/common/icon/error_icon.svg"
        alt=""
      />
      <p className="Error__text">{errorMessage}</p>
      <A to="/" className="Error__btn">
        ホーム画面を表示する
      </A>
    </div>
  );
}

Error.defaultProps = defaultProps;
