import React, { useEffect, ReactNode, useRef } from 'react';
import { AgendaModel } from '../../../api/model/agenda';
import A from '../../atoms/A';
import Nav from '../../molecules/Nav';
import Skeleton from 'components/atoms/Skeleton';
import { HouseholdModel } from '../../../api/model/household';

const defaultProps = {
  fetching: true,
};

type Props = {
  agenda?: AgendaModel;
  children?: ReactNode;
  fetching: boolean;
} & typeof defaultProps;

export default function ScheduleArticles(props: Props) {
  const { agenda, fetching } = props;
  const imageEl = useRef<HTMLImageElement>(null);

  // HACK: 画像のちらつきを抑える
  // とりあえずの回避策
  useEffect(() => {
    if (imageEl.current && agenda?.article_image)
      imageEl.current.src = agenda?.article_image;
    return () => {
      if (imageEl.current) imageEl.current.src = '';
    };
  });

  const isCompletedAllHousehold = (households: HouseholdModel[]) => {
    const targetHouseholds = households.filter(
      (household) => !household.ignored
    );
    return targetHouseholds.every((household) => household.checked);
  };

  const handleClassName = (household: HouseholdModel) => {
    let checkBoxClassName = '';
    switch (household.color) {
      case '#c97179':
        checkBoxClassName += '-red';
        break;
      case '#e6c671':
        checkBoxClassName += '-yellow';
        break;
      case '#9cc06c':
        checkBoxClassName += '-green';
        break;
      case '#7cb5d1':
        checkBoxClassName += '-blue';
        break;
      case '#7495c6':
        checkBoxClassName += '-purple';
        break;
    }
    if (household.ignored) {
      checkBoxClassName += ' -ignored';
    } else if (household.checked) {
      checkBoxClassName += ' -checked';
    }

    return checkBoxClassName;
  };

  return (
    <>
      <Nav title={agenda?.name} />

      <div id="pageTop" className="container -with-bar">
        <div className="Media__root-column">
          {fetching && <Skeleton type="scheduleArticle" />}
          {!fetching && (
            <div>
              <div className="Media__kv-column">
                <img alt="" className="Media__kv-column-image" ref={imageEl} />
                <p className="Media__kv-number">
                  {agenda?.ignored ? '0' : agenda?.completed_articles_count}/
                  {agenda?.total_articles_count}
                </p>
              </div>
              {agenda?.articles?.length === 0 ? (
                <div className="Column__none">
                  <p>確認するコラムはありません</p>
                </div>
              ) : (
                <div className="Media__box Media__box-column">
                  <div className="Media__box-inner-column">
                    <ul
                      className={`Media__list-column ${
                        agenda?.ignored ? '-ignored' : ''
                      }`}
                    >
                      {agenda?.articles?.map((article) => {
                        return (
                          <li
                            className={`${
                              !agenda.ignored &&
                              (isCompletedAllHousehold(article.households)
                                ? '-checked'
                                : '')
                            }`}
                            key={article.id}
                          >
                            <A to={`/articles/${article.id}`}>
                              <span>{article.name}</span>
                              <ul className="check-list">
                                {article.households.length > 1 &&
                                  article.households.map((household, index) => (
                                    <li
                                      key={index}
                                      className={handleClassName(household)}
                                    >
                                      <span>{household.name}</span>
                                    </li>
                                  ))}
                              </ul>
                            </A>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

ScheduleArticles.defaultProps = defaultProps;
