import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import viewportUnitsBuggyfill from 'viewport-units-buggyfill';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store from './App/store';
import 'react-reflex/styles.css';
import { createRoot } from 'react-dom/client';
import App from 'App';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import Home from 'features/appointment/Home';
import Files from 'features/dataFile/Files';
import IdSheet from 'features/idSheet/IdSheet';
import Messages from 'features/message/Message';
import ScheduleArticles from 'features/agenda/ScheduleArticles';
import Schedule from 'features/appointment/Schedules';
import Furniture from 'features/furniture/furniture';
import ArticleDetail from 'features/article/ArticleDetail';
import Articles from 'features/designStep/Articles';
import AppointmentDetail from 'features/appointment/AppointmentDetail';
import NotificationSetting from 'features/notificationSetting/notificationSetting';
import Tasks from 'features/task/Tasks';
import Materials from 'features/material/Materials';
import Error from 'components/pages/Error';
import Tutorial from 'components/pages/Tutorials';
import Keep from 'components/pages/Keeps/index';
import ThreeDViewer from 'components/pages/ThreeDViewer';
import Auth from 'features/auth/Auth';

viewportUnitsBuggyfill.init();

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        element: <Auth />,
        children: [
          {
            path: '/',
            element: <Home />,
          },
          {
            path: 'files',
            element: <Files />,
          },
          {
            path: 'idsheet',
            element: <IdSheet />,
          },
          {
            path: 'agendas/:id',
            element: <ScheduleArticles />,
          },
          {
            path: 'messages',
            element: <Messages />,
          },
          {
            path: 'schedules',
            element: <Schedule />,
          },
          {
            path: 'furniture',
            element: <Furniture />,
          },
          {
            path: 'articles/:id',
            element: <ArticleDetail />,
          },
          {
            path: 'articles',
            element: <Articles />,
          },
          {
            path: 'appointments/:id',
            element: <AppointmentDetail />,
          },
          {
            path: 'tutorials',
            element: <Tutorial />,
          },
          {
            path: 'keeps',
            element: <Keep />,
          },
          {
            path: 'three_d_viewer',
            element: <ThreeDViewer />,
          },
          {
            path: 'tasks',
            element: <Tasks />,
          },
          {
            path: 'materials',
            element: <Materials />,
          },
          {
            path: 'notification_settings',
            element: <NotificationSetting />,
          },
          {
            path: 'error',
            element: <Error />,
          },
          {
            path: '*',
            element: <Navigate to="/error" />,
          },
        ],
      },
    ],
  },
]);

const render = () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
  });
}

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
serviceWorker.register();
