import React, { ReactNode } from 'react';
import { AppointmentModel } from '../../../api/model/appointment';
import { MilestoneModel } from 'api/model/milestone';
import { HouseholdFlagModel } from 'api/model/agenda';
import { shortdateStringJp, datetimeStringJp, isNullValue } from 'libs/date';
import A from '../../atoms/A';
import Skeleton from 'components/atoms/Skeleton';
import HouseholdFlag from '../../atoms/HouseholdFlag';
import { HouseholdModel } from 'api/model/household';
import { displayHouseholds } from 'features/appointment/Schedules';
import { isBefore, startOfDay } from 'date-fns';
import PageTitle from 'components/atoms/PageTitle';
import { DOWNLOAD_FILE_EXTENSIONS } from 'libs/constants';
import { downloadFileFromURL } from 'libs/downloadFileFromUrl';
import { getThumbnail } from 'libs/getThumbnail';

const defaultProps = {
  fetching: true,
};

type Props = {
  children?: ReactNode;
  appointment?: AppointmentModel;
  milestone?: MilestoneModel;
  fetching: boolean;
  handedOver4monthsAgo: boolean;
  households?: HouseholdModel[];
  hasUsingHouseholds: boolean;
  ignoreVersionMaster?: boolean;
} & typeof defaultProps;

export default function AppointmentDetail(props: Props) {
  const {
    appointment,
    fetching,
    handedOver4monthsAgo,
    households,
    hasUsingHouseholds,
    ignoreVersionMaster,
  } = props;

  const normalAppointment = () => {
    return appointment?.appointment_type !== 'contracted';
  };

  const handleClassNames = (householdFlags: HouseholdFlagModel[]) => {
    // 【多世帯ではない場合】 ignored または 赤flagが表示される場合に`-flag-single`クラスがつく。
    if (households?.length === 1 && householdFlags.length === 1)
      return '-flag-single';
    // 【多世帯の場合】 ignored または flagがひとつでも表示される場合に`-flag-bg`クラスがつく。
    if (householdFlags.length !== 0) return '-flag-bg';
    return '';
  };

  const handleFileClick = (
    url: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    filename: string
  ) => {
    if (event.target) {
      event.preventDefault();
    }
    if (url.includes('.glb')) {
      event.preventDefault(); // デフォルトのリンククリック動作を停止
      localStorage.setItem('three_d_file_url', url);
      const viewerURL = '/three_d_viewer';
      window.open(viewerURL, '_blank', 'noreferrer'); // 別タブで3DファイルのURLを開く
    } else if (
      DOWNLOAD_FILE_EXTENSIONS.some((extension) => filename.includes(extension))
    ) {
      downloadFileFromURL(url, filename);
    } else {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  return (
    <>
      <PageTitle>スケジュール</PageTitle>
      <div id="pageTop" className="container -with-bar">
        {fetching && <Skeleton type="APPO" />}
        {!fetching && appointment?.appointment_type === 'contracted' && (
          <div className="Home__slide">
            <div className="Home__slide-content -active">
              <div className="Home__slide-label">
                <div className="Home__slide-label-text">
                  <p className="text-date">
                    {appointment &&
                      !isNullValue(appointment.date) &&
                      shortdateStringJp(appointment.date)}
                  </p>
                  <h2 className="text-title">{appointment?.title}</h2>
                </div>
                <div className="Home__slide__btns">
                  <A back>
                    <span className="Home__article-btn-return" />
                  </A>
                </div>
              </div>
            </div>
            <div className="Home__article">
              <div className="Home__inner">
                <div className="Home__article-body">
                  <p className="Home__article-lead">
                    この度はご契約頂き、
                    <br />
                    誠にありがとうございます。
                  </p>
                  <p>
                    理想のお住まいを一緒につくっていくため引き続き打ち合わせのご協力をお願いいたします。
                  </p>
                  <p className="Home__article-note">
                    ※一部地域、物件は住まいづくりナビを利用できない場合がございますので、担当者にご確認ください。
                  </p>
                </div>
              </div>
            </div>
            {!ignoreVersionMaster && (
              <div className="Home__media -agenda -icon-document">
                <div className="Home__inner">
                  <h3 className="Home__heading -icon-document">
                    事前にお読みください
                  </h3>
                  <ul className="Home__media-list">
                    {appointment?.agendas?.map((agenda) => {
                      return (
                        <li
                          className={`${handleClassNames(
                            agenda.household_flags
                          )}`}
                          key={agenda.name}
                        >
                          {households &&
                            households?.length > 1 &&
                            agenda.household_flags.map((householdFlag) => (
                              <HouseholdFlag
                                colorCode={householdFlag.name}
                                key={agenda.id}
                              />
                            ))}
                          <A to={`/agendas/${agenda.id}`}>
                            <img src={agenda.image} alt="" />
                            <span className="text-name">
                              {agenda.name}
                              <span className="text-number">
                                {agenda.ignored
                                  ? '0'
                                  : agenda.completed_articles_count}
                                /{agenda.total_articles_count}
                              </span>
                            </span>
                          </A>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
        {!fetching && appointment?.appointment_type === 'normal' && (
          <div className="Home__slide">
            <div className="Home__slide-item -active">
              <div className="Home__slide-label">
                <div className="Home__slide-label-text">
                  <p className="text-date">
                    {appointment && shortdateStringJp(appointment.date)}
                  </p>
                  <h2 className="text-title">{appointment?.title}</h2>
                </div>
                <div className="Home__slide__btns">
                  <A back>
                    <span className="Home__article-btn-return" />{' '}
                  </A>
                </div>
              </div>
              <div className="Home__mtg">
                <div className="Home__inner">
                  <div className="Home__mtg-desc">
                    <ul className="Home__mtg-desc-list">
                      <li>
                        <span className="Home__mtg-desc-label">時間：</span>
                        <span>
                          {appointment &&
                            normalAppointment() &&
                            `${datetimeStringJp(
                              appointment.begin_at
                            )}〜${datetimeStringJp(appointment.end_at)}`}
                        </span>
                      </li>
                      <li>
                        <span className="Home__mtg-desc-label">場所：</span>
                        <span>{normalAppointment() && appointment?.place}</span>
                      </li>
                      {displayHouseholds(hasUsingHouseholds, appointment) && (
                        <li className="Home__flex-box">
                          <span className="Home__mtg-desc-label">
                            参加世帯：
                          </span>
                          <ul>
                            {appointment.households.map((household, index) => (
                              <li key={index}>{household.name}</li>
                            ))}
                          </ul>
                        </li>
                      )}
                      {!isBefore(
                        new Date(appointment.date),
                        startOfDay(new Date())
                      ) &&
                        appointment?.display_conference && (
                          <div>
                            <li>
                              <A
                                href={appointment.conference_url}
                                className="Home__mtg-desc-link"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="icon-link"
                                  src="/assets/img/schedule/icon/join_conference.svg"
                                  alt="オンライン打合せ"
                                />
                              </A>
                            </li>
                            <li>
                              <span className="Home__mtg-desc-url">
                                {appointment.conference_url.replace(
                                  'https://',
                                  ''
                                )}
                              </span>
                            </li>
                          </div>
                        )}
                    </ul>
                  </div>
                  <h3 className="Home__heading -icon-check">連絡事項</h3>
                  <div className="Home__mtg-notice">
                    <p>{normalAppointment() && appointment?.note}</p>
                  </div>
                </div>
              </div>
              {normalAppointment() && (
                <div className="Home__media -attach -icon-file">
                  <div className="Home__inner">
                    <h3 className="Home__heading -icon-file">打合せ資料</h3>
                    <ul className="Home__media-list">
                      {appointment?.record_files?.map((record_file) => {
                        return (
                          <li key={record_file.name}>
                            <A
                              href={record_file.url}
                              onClick={(
                                event: React.MouseEvent<
                                  HTMLAnchorElement,
                                  MouseEvent
                                >
                              ) =>
                                handleFileClick(
                                  record_file.url,
                                  event,
                                  record_file.name
                                )
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={getThumbnail(
                                  record_file.url,
                                  record_file.thumb_url
                                )}
                                alt="ファイルサムネイル"
                              />
                              <span className="text-name">
                                {record_file.name}
                              </span>
                            </A>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {normalAppointment() && (
                <div className="Home__media -attach -icon-file">
                  <div className="Home__inner">
                    <h3 className="Home__heading -icon-file">その他資料</h3>
                    <ul className="Home__media-list">
                      {appointment?.related_files?.map((related_file) => {
                        return (
                          <li key={related_file.name}>
                            <A
                              href={related_file.url}
                              onClick={(
                                event: React.MouseEvent<
                                  HTMLAnchorElement,
                                  MouseEvent
                                >
                              ) =>
                                handleFileClick(
                                  related_file.url,
                                  event,
                                  related_file.name
                                )
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={getThumbnail(
                                  related_file.url,
                                  related_file.thumb_url
                                )}
                                alt="ファイルサムネイル"
                              />
                              <span className="text-name">
                                {related_file.name}
                              </span>
                            </A>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {!ignoreVersionMaster && (
                <div className="Home__media -agenda -icon-document">
                  <div className="Home__inner">
                    <h3 className="Home__heading -icon-document">
                      事前にお読みください
                    </h3>
                    <ul className="Home__media-list">
                      {appointment?.agendas?.map((agenda) => {
                        return (
                          <li
                            key={agenda.name}
                            className={`${handleClassNames(
                              agenda.household_flags
                            )}`}
                          >
                            {households &&
                              households?.length > 1 &&
                              agenda.household_flags.map((householdFlag) => (
                                <HouseholdFlag
                                  colorCode={householdFlag.name}
                                  key={agenda.id}
                                />
                              ))}
                            <A to={`/agendas/${agenda.id}`}>
                              <img src={agenda.image} alt="" />
                              <span className="text-name">
                                {agenda.name}
                                <span className="text-number">
                                  {agenda.ignored
                                    ? '0'
                                    : agenda.completed_articles_count}
                                  /{agenda.total_articles_count}
                                </span>
                              </span>
                            </A>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {!fetching &&
          (appointment?.appointment_type === 'sekkei_process' ||
            appointment?.appointment_type === 'kouji_process') && (
            <div className="Home__slide">
              <div className="Home__slide-content -active">
                <div className="Home__slide-label">
                  <div className="Home__slide-label-text">
                    <p className="text-date">
                      {appointment &&
                        !isNullValue(appointment.date) &&
                        shortdateStringJp(appointment.date)}
                    </p>
                    <h2 className="text-title">{appointment.title}</h2>
                  </div>
                  <div className="Home__slide__btns">
                    <A back>
                      <span className="Home__article-btn-return" />{' '}
                    </A>
                  </div>
                </div>
              </div>
              {!ignoreVersionMaster && appointment?.agendas?.length !== 0 && (
                <div className="Home__media -agenda -icon-document">
                  <div className="Home__inner">
                    <h3 className="Home__heading -icon-document">
                      事前にお読みください
                    </h3>
                    <ul className="Home__media-list">
                      {appointment?.agendas?.map((agenda) => {
                        return (
                          <li
                            className={`${handleClassNames(
                              agenda.household_flags
                            )}`}
                            key={agenda.name}
                          >
                            {households &&
                              households?.length > 1 &&
                              agenda.household_flags.map((householdFlag) => (
                                <HouseholdFlag
                                  colorCode={householdFlag.name}
                                  key={agenda.id}
                                />
                              ))}
                            <A to={`/agendas/${agenda.id}`}>
                              <img src={agenda.image} alt="" />
                              <span className="text-name">
                                {agenda.name}
                                <span className="text-number">
                                  {agenda.completed_articles_count}/
                                  {agenda.total_articles_count}
                                </span>
                              </span>
                            </A>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        {!fetching && appointment?.appointment_type === 'handovered' && (
          <div className="Home__slide">
            <div className="Home__slide-content -active">
              <div className="Home__slide-label">
                <div className="Home__slide-label-text">
                  <p className="text-date">
                    {appointment &&
                      !isNullValue(appointment.date) &&
                      shortdateStringJp(appointment.date)}
                  </p>
                  <h2 className="text-title">{appointment?.title}</h2>
                </div>
                <div className="Home__slide__btns">
                  <A back>
                    <span className="Home__article-btn-return" />{' '}
                  </A>
                </div>
              </div>
            </div>
            <div className="Home__article">
              <div className="Home__inner">
                <div className="Home__article-body">
                  <p className="Home__article-lead">
                    設計打ち合わせが完了しました。
                    <br />
                    お住まいが完成するまでの間に
                    <br />
                    こちらのご準備をお願いいたします。
                  </p>
                </div>
              </div>
            </div>
            {handedOver4monthsAgo && (
              <div>
                <p className="Home__handedOvered">
                  お引渡しから4ヶ月が経過しているため、掲示板の投稿など編集はできません。
                  <br />
                  引渡し1年後には利用ができなくなります。
                  <br />
                  お住まいの不具合については
                  <a
                    href="https://www.mitsuihomeclub.com/line-howtouse/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    こちら
                  </a>
                  からお問い合わせください。
                </p>
              </div>
            )}
            <div className="Home__media -agenda -icon-document">
              <div className="Home__inner">
                <h3 className="Home__heading -icon-document">
                  お引渡しまでのご準備
                </h3>
                <ul className="Home__media-list">
                  {appointment?.agendas?.map((agenda) => {
                    return (
                      <li
                        className={`${handleClassNames(
                          agenda.household_flags
                        )}`}
                        key={agenda.name}
                      >
                        <A to={`/agendas/${agenda.id}`}>
                          <img src={agenda.image} alt="" />
                          <span className="text-name">
                            {agenda.name}
                            <span className="text-number">
                              {agenda.completed_articles_count}/
                              {agenda.total_articles_count}
                            </span>
                          </span>
                        </A>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

AppointmentDetail.defaultProps = defaultProps;
