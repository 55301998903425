import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Scroll from 'react-scroll';

type Props = {
  options?: any;
  containerId?: string;
};

const scroll = Scroll.animateScroll;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontSizeLarge: {
      color: '#7b7b7b',
      fontSize: '32px',
    },
    buttonStyle: {
      position: 'fixed',
      zIndex: 300,
      bottom: '6.8rem',
      right: '1.4rem',
      [theme.breakpoints.up(768)]: {
        right: '5.4rem',
      },
    },
  })
);

const FloatingActionButtons: FC<Props> = (props) => {
  const { containerId, options, ...rest } = props;
  const classes = useStyles();

  const _options = {
    smooth: 'easeInOutQuad',
    duration: 400,
    ...options,
  };

  return (
    <Fab
      color="secondary"
      aria-label="scroll up"
      className={classes.buttonStyle + ' u-print-hidden'}
      onClick={() => {
        if (containerId) {
          // containerId の設定はあるが、dom に存在しなかった場合は、body scroll
          if (document.getElementById(containerId)) {
            scroll.scrollToTop({ ..._options, containerId });
          } else {
            scroll.scrollToTop(_options);
          }
        } else {
          scroll.scrollToTop(_options);
        }
      }}
      {...rest}
    >
      <ExpandLessIcon className={classes.fontSizeLarge} fontSize="large" />
    </Fab>
  );
};

export default FloatingActionButtons;
