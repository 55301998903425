import React from 'react';
import A from '../../atoms/A';
import { useLocation } from 'react-router-dom';

export const defaultProps = {
  onOpen: () => {},
  open: false,
};
export type Props = {
  onOpen: () => void;
  location?: any;
} & typeof defaultProps;

export type Hash = {
  [key: string]: string;
};

export const Header = (props: Props) => {
  const { onOpen, open } = props;
  const location = useLocation();

  const titles: Hash = {
    '/': 'image',
    '/idsheet': '確認事項',
    '/messages': '掲示板',
    '/files': 'ファイル',
    '/schedules': 'スケジュール',
    '/furniture': '家具家電リスト',
    '/tutorials': '使い方',
    '/keeps': 'データの保存方法',
    '/notification_settings': '通知設定',
    '/articles': 'コラム一覧',
    '/tasks': 'やることリスト',
  };

  const handleClick = () => {
    if (!open) {
      onOpen();
    }
  };

  const appointment_path_title = () => {
    if (location.pathname.includes('/appointments')) {
      return 'スケジュール';
    }
  };

  // location.pathnameが /appointments/〜（スケジュール詳細画面）の場合、タイトルは「スケジュール」
  const title = titles[location.pathname] || appointment_path_title();

  return (
    <>
      {title && (
        <header
          className="Header__root-sp"
          style={{ left: open ? '33.0rem' : '0' }}
        >
          {/* SPのときのみ表示 */}
          <span className="Header__btn-sp" onClick={handleClick}>
            <span className="bar01" />
            <span className="bar02" />
            <span className="bar03" />
          </span>
          <h1 className="Header__title">
            {title === 'image' ? (
              <span>
                <img
                  src="/assets/img/common/logo_nav.svg"
                  alt="住まいづくりナビ"
                />
              </span>
            ) : (
              title
            )}
          </h1>
          <A to="/" className="Header__home">
            <img src="/assets/img/common/icon/sp_nav_home_on.svg" alt="" />
          </A>
        </header>
      )}
    </>
  );
};

Header.defaultProps = defaultProps;
export default Header;
