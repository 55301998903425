import { apiClient } from './client';
import { MessageModel } from './model/message';

export type FileParams = {
  fileId?: number | string;
  name: string;
  url: string;
  image?: File | string;
};

export type FetchRes = {
  data: {
    messages: MessageModel[];
    message_count: number;
  };
};

export type searchParams = {
  filterType: string;
  searchKeywords?: string;
  page: number;
};

export async function fetch() {
  return apiClient.get<FetchRes>(`/messages`);
}

export async function search(params: searchParams) {
  return apiClient.get<FetchRes>(
    `/messages?search_keywords=${params.searchKeywords}&filter_type=${params.filterType}`
  );
}

export type CreateParams = {
  body: string;
  files?: FileParams[];
  status?: string;
  quotableId?: number;
  quotableType?: string;
};

export type CreateRes = {
  data: MessageModel;
};

export type UpdateParams = {
  messageId: number;
  body: string;
  files?: FileParams[];
  status?: string;
};

export type UpdateRes = {
  data: MessageModel;
};

export async function create(params: CreateParams) {
  const formData = new FormData();
  formData.append('body', params.body);
  if (params.quotableId && params.quotableType) {
    formData.append('quotable_id', params.quotableId.toString());
    formData.append('quotable_type', params.quotableType);
  }
  params.files?.map((file) => {
    Object.entries(file).forEach(([key, value]) => {
      const newKey = key === 'fileId' ? 'file_id' : key;

      formData.append('files[]' + newKey, value as any);
    });
  });
  if (params.status) {
    formData.append('status', params.status);
  }
  return apiClient.post<CreateRes>(`/messages`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function update(params: UpdateParams) {
  const formData = new FormData();
  formData.append('body', params.body);
  params.files?.map((file) => {
    Object.entries(file).forEach(([key, value]) => {
      const newKey = key === 'fileId' ? 'file_id' : key;
      formData.append('files[]' + newKey, value as any);
    });
  });
  if (params.status) {
    formData.append('status', params.status);
  }
  return apiClient.patch<UpdateRes>(`/messages/${params.messageId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export type DestroyParams = {
  fileId: number;
  messageId: number;
};
export type DestroyRes = {
  data: {
    messages: MessageModel[];
  };
};

export async function destroy(params: DestroyParams) {
  return apiClient.delete<DestroyRes>(
    `/messages/${params.messageId}/data_files/${params.fileId}`
  );
}

export type MessageDestroyParams = {
  messageId: number;
};

export type MessageDestroyRes = {
  data: {
    messages: MessageModel[];
  };
};

export async function messageDestroy(params: MessageDestroyParams) {
  return apiClient.delete<DestroyRes>(`/messages/${params.messageId}`);
}

export type ScrollFetchParams = {
  page: number;
  filterType: string;
  searchKeywords?: string;
};

export type ScrollFetchRes = {
  data: {
    messages: MessageModel[];
  };
};
export async function scrollFetch(params: ScrollFetchParams) {
  return apiClient.get<ScrollFetchRes>(
    `/messages?page=${params.page}&search_keywords=${params.searchKeywords}&filter_type=${params.filterType}`
  );
}
