import PageTitle from 'components/atoms/PageTitle';
import React, { useCallback, useEffect, useRef } from 'react';

export default function Tutorial() {
  let currentHeight: number;
  let selectedTab = 1;
  const iframeRef = useRef<HTMLIFrameElement>(null);

  if (window.innerWidth >= 1350) {
    currentHeight = 10000;
  } else if (window.innerWidth >= 1230) {
    currentHeight = 9800 + (window.innerWidth - 1230) * 3.3; // 画面サイズ1230pxで調律 innerWidth: (1230px ~ 1349px)
  } else if (window.innerWidth >= 769) {
    currentHeight = 11000 + (window.innerWidth - 769) * 9; // 画面サイズ769pxで調律 innerWidth: (769x ~ 1229px)
  } else {
    currentHeight = 10500 + (window.innerWidth - 370) * 6.5; //画面サイズ370pxで調律 innerWidth: (0px ~ 768px)
  }
  const adjustIframeHeight = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      iframe: any,
      changeType?: string,
      tabId?: number,
      panelId?: number,
      panelIsOpen?: boolean
    ) => {
      if (changeType === 'panel') {
        let plusPx = 0;
        let heightPanelMapping: { [key: number]: number };
        if (window.innerWidth >= 1230) {
          heightPanelMapping = {
            1: 600,
            2: 350,
            3: 500,
            4: 500,
            5: 500,
          };
        } else if (window.innerWidth >= 769) {
          plusPx = window.innerWidth - 769;
          heightPanelMapping = {
            1: 500,
            2: 300,
            3: 650 + plusPx,
            4: 1000 + plusPx,
            5: 1000 + plusPx,
          };
        } else {
          plusPx = window.innerWidth - 370;
          heightPanelMapping = {
            1: 600 + plusPx,
            2: 400 + plusPx,
            3: 500 + plusPx,
            4: 800 + plusPx,
            5: 800 + plusPx,
          };
        }

        // panelの開閉(isOpen)に合わせて、heightMappingのpanelIdの値を加算・減算する
        if (
          typeof panelId !== 'undefined' &&
          Object.prototype.hasOwnProperty.call(heightPanelMapping, panelId)
        ) {
          if (panelIsOpen) {
            currentHeight = currentHeight + heightPanelMapping[panelId];
          } else {
            currentHeight = currentHeight - heightPanelMapping[panelId];
          }
        }
      }

      // タブインデックスとそれに対応する高さのマッピング。基本のheightMappingと画面サイズの増加量に対するpxの増加率を定義
      let tabHeightMapping: { [key: number]: number };
      let plusPx = 0;
      if (window.innerWidth >= 1230) {
        tabHeightMapping = {
          1: 800,
          2: 3200,
          3: 800,
          4: 900,
          5: 1500,
          6: 800,
          7: 900,
          8: 800,
          9: 800,
          10: 1500,
        };
      } else if (window.innerWidth >= 769) {
        plusPx = window.innerWidth - 769;
        tabHeightMapping = {
          1: 1000 + plusPx,
          2: 3200 + plusPx,
          3: 300 + plusPx,
          4: 500 + plusPx,
          5: 600 + plusPx,
          6: 400 + plusPx,
          7: 1000 + plusPx,
          8: 400 + plusPx,
          9: 400 + plusPx,
          10: 900 + plusPx,
        };
      } else {
        plusPx = (window.innerWidth - 370) * 1.5;
        tabHeightMapping = {
          1: 900 + plusPx,
          2: 3400 + plusPx,
          3: 400 + plusPx,
          4: 500 + plusPx,
          5: 600 + plusPx,
          6: 400 + plusPx,
          7: 900 + plusPx,
          8: 400 + plusPx,
          9: 400 + plusPx,
          10: 900 + plusPx,
        };
      }

      let setHeight: string;
      if (
        typeof tabId !== 'undefined' &&
        Object.prototype.hasOwnProperty.call(tabHeightMapping, tabId)
      ) {
        selectedTab = tabId;
        setHeight = currentHeight + tabHeightMapping[selectedTab] + 'px';
        iframe.style.height = setHeight;
      } else {
        setHeight = currentHeight + tabHeightMapping[selectedTab] + 'px';
        iframe.style.height = setHeight;
      }
    },
    []
  );

  useEffect(() => {
    // 各種機能のボタン押下後、メニュー番号によってiframeのheightを変更する処理
    window.addEventListener('message', function (event) {
      if (event.data.type && event.data.type === 'iframeResized') {
        const iframe = document.querySelector('.iframe-tutorial');
        adjustIframeHeight(
          iframe,
          event.data.changeType,
          event.data?.tabId,
          event.data?.panelId,
          event.data?.panelIsOpen
        );
      }
    });
  }, [adjustIframeHeight]);

  // iframeレンダリング後、１ホームがすべて表示されないためクリックをする
  const onIframeLoad = useCallback(
    (e: React.SyntheticEvent<HTMLIFrameElement>) => {
      adjustIframeHeight(e.currentTarget);
      const iframe = iframeRef.current;
      if (iframe?.contentWindow) {
        const homeTab = iframe.contentWindow.document.querySelector(
          '.btn01 a'
        ) as HTMLElement;
        if (homeTab) {
          homeTab.click();
        }
      }
    },
    [adjustIframeHeight]
  );

  return (
    <>
      <PageTitle>使い方</PageTitle>
      <div className="Tutorial__container">
        <iframe
          src="/tutorial/index.html"
          className="iframe-tutorial"
          width="100%"
          onLoad={onIframeLoad}
          title="Tutorial"
          scrolling="no"
          ref={iframeRef}
        ></iframe>
      </div>
    </>
  );
}
