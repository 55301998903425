import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../App/store';
import { getUser } from '../../api/github';
import { login } from '../../api/user';
import { UserModel } from '../../api/model/user';
import { serializeError } from 'serialize-error';

type UserLoaded = {
  name: string;
};

type LoginLoaded = {
  user: UserModel;
};

type SsoFailure = {
  message: string;
};

type UserState = {
  name: string;
  currentUser: UserModel | null;
  deletedUser: boolean;
  loggedIn: boolean;
  loading: boolean;
  error: any;
  ssoErrorMessage: string;
  slideOpen: boolean;
  should_review: boolean;
};

const initialState: UserState = {
  name: '',
  currentUser: null,
  deletedUser: false,
  loggedIn: false,
  loading: false,
  error: null,
  ssoErrorMessage: '',
  slideOpen: false,
  should_review: false,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleSlideNav(state) {
      if (state.slideOpen === false) {
        state.slideOpen = true;
      } else {
        state.slideOpen = false;
      }
    },
    getUserStart(state) {
      state.loading = true;
    },
    getUserSuccess(state, action: PayloadAction<UserLoaded>) {
      const { name } = action.payload;
      state.name = name;
      state.loading = false;
      state.error = null;
    },
    getUserFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    loginUserStart(state) {
      state.loading = true;
    },
    loginUserSuccess(state, action: PayloadAction<LoginLoaded>) {
      const { user } = action.payload;
      state.currentUser = user;
      state.loggedIn = true;
      state.loading = false;
      state.error = null;
    },
    loginUserFailure(state, action: PayloadAction<Error | string>) {
      state.error = serializeError(action.payload);
      if (action.payload === '削除済みユーザー') {
        state.deletedUser = true;
      }
      state.loggedIn = false;
      state.loading = false;
    },
    ssoFailure(state, action: PayloadAction<SsoFailure>) {
      const { message } = action.payload;
      state.ssoErrorMessage = message;
    },
    resetMessageCount(state) {
      if (state.currentUser) {
        state.currentUser.unread_message_count = 0;
      }
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  toggleSlideNav,
  getUserSuccess,
  getUserStart,
  getUserFailure,
  loginUserSuccess,
  loginUserStart,
  loginUserFailure,
  resetMessageCount,
  resetError,
} = user.actions;
export default user.reducer;

export const fetchUser =
  (name: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getUserStart());
      const res = await getUser({ name });
      dispatch(getUserSuccess({ name: res.data.login }));
    } catch (err: any) {
      dispatch(getUserFailure(err));
      throw err;
    }
  };

export const resetNotificationCount = (): AppThunk => async (dispatch) => {
  try {
    dispatch(resetMessageCount());
  } catch (err: any) {
    throw err;
  }
};

export const loginUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loginUserStart());
    const res = await login();
    if (res.data.data.user) {
      dispatch(loginUserSuccess({ user: res.data.data.user }));
    } else if (res.data.data.deleted_user) {
      throw '削除済みユーザー';
    }
  } catch (err: any) {
    dispatch(loginUserFailure(err));
    throw err;
  }
};
