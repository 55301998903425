import React, { ReactNode } from 'react';
import A from '../../atoms/A';

const defaultProps = {
  message: '',
};

type Props = {
  children?: ReactNode;
  message: string;
} & typeof defaultProps;

export default function SsoError(props: Props) {
  const { message } = props;
  return (
    <div className="Error">
      <h1 className="Error__title -icon-caution">エラーが発生しました</h1>
      <img
        className="Error__image"
        src="/assets/img/common/icon/error_icon.svg"
        alt=""
      />
      <p className="Error__text">{message}</p>
      <A href={process.env.REACT_APP_CLUB_PAGE} className="Error__btn">
        三井ホーム倶楽部に戻る
      </A>
    </div>
  );
}

SsoError.defaultProps = defaultProps;
