import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { useNavigate } from 'react-router';
import Loading from '../../../features/root/Loading/index';
import Error from '../../../features/root/Error/index';
import NavBase, { Item } from '../../atoms/NavBase';
import FloatingActionButtons from '../../atoms/FloatingScrollTop/index';
import FloatingServerLabel from '../../atoms/FloatingServerLabel/index';
import Maintenance from '../../atoms/Maintenance';
import Header from '../../organisms/Header';
import SideMenu from '../../organisms/SideMenu';
import SsoError from '../../pages/SsoError';
import classNames from 'classnames';

const defaultProps = {
  path: 'home',
};

export type Props = {
  children: ReactNode;
  handleSlideNav: () => void;
  slideOpen: boolean;
} & typeof defaultProps;

const noPartsPages = ['/error'];
const noSideBarPages = ['/messages/post/', '/three_d_viewer'];
const noTopPaddingPages = ['/'];
const noToTopButton = [
  '/messages',
  '/notification_settings',
  '/messages/post/',
  '/tutorials',
  '/keeps',
  '/three_d_viewer',
];
const noBottomMenuToSp = ['/three_d_viewer'];

export default function Root(props: Props) {
  const { children, path, handleSlideNav, slideOpen } = props;
  const navigate = useNavigate();
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const ssoErrorMessage = useSelector(
    (state: RootState) => state.user.ssoErrorMessage
  );
  const user = useSelector((state: RootState) => state.user.currentUser);

  const navProps = {
    ulClass: 'Nav__list-sp -menu',
    textClass: 'Nav__list-item',
    items: [
      {
        id: 'messages',
        path: '/messages',
        class: '-messages',
      },
      {
        id: 'schedule',
        path: '/schedules',
        class: '-schedule',
      },
      {
        id: 'tasks',
        path: '/tasks',
        class: '-tasks',
      },
      {
        id: 'furniture',
        path: '/furniture',
        class: '-furniture',
      },
      {
        id: 'files',
        path: '/files',
        class: '-files',
      },
    ],
    current: path,
    unreadMessageCount: user?.unread_message_count,
    ignoreVersionMaster: user?.ignore_version_master,
    onClick(item: Item) {
      navigate(item.path);
    },
  };

  const closeSlideNav = () => {
    if (slideOpen) {
      handleSlideNav();
    }
  };

  // メンテナンスモード中は本番テストデータ(本番疎通様邸)のみ作業可能
  if (
    process.env.REACT_APP_MAINTENANCE === 'true' &&
    loggedIn &&
    currentUser?.id !== 10641
  ) {
    return <Maintenance />;
  } else {
    return (
      <>
        <div className="wrap">
          {loggedIn && <Error />}
          <Loading />
          {!loggedIn && ssoErrorMessage && (
            <>
              <SsoError message={ssoErrorMessage} />
            </>
          )}
          <div className="content-wrap">
            {loggedIn && noPartsPages.includes(path) && <>{children}</>}
            {!noPartsPages.includes(path) && (
              <>
                {loggedIn && !noSideBarPages.includes(path) && (
                  <SideMenu open={slideOpen} onChange={handleSlideNav} />
                )}
                <main
                  className={classNames('main-content', {
                    'main-content--without-headparts':
                      noTopPaddingPages.includes(path),
                  })}
                  onClick={closeSlideNav}
                >
                  {loggedIn && (
                    <Header onOpen={handleSlideNav} open={slideOpen} />
                  )}
                  {children}
                  {!noToTopButton.includes(path) && (
                    <FloatingActionButtons containerId="pageTop" />
                  )}
                  {process.env.REACT_APP_ENV_NAME !== 'development' &&
                    process.env.REACT_APP_ENV_NAME !== 'production' && (
                      <FloatingServerLabel />
                    )}
                  {loggedIn && !noBottomMenuToSp.includes(path) && (
                    <nav
                      className="Nav__root-sp -bottom"
                      style={{ left: slideOpen ? '33.0rem' : '0' }}
                    >
                      <NavBase {...navProps} />
                    </nav>
                  )}
                </main>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

Root.defaultProps = defaultProps;
