import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../App/store';
import { HouseholdModel } from 'api/model/household';
import { serializeError } from 'serialize-error';
import { fetch } from '../../api/household';

type HouseholdLoaded = {
  households: HouseholdModel[];
};

type HouseholdState = {
  households: HouseholdModel[];
  loading: boolean;
  error: any;
};

const initialState: HouseholdState = {
  households: [],
  loading: false,
  error: null,
};

const household = createSlice({
  name: 'household',
  initialState,
  reducers: {
    fetchHouseholdsStart(state) {
      state.loading = true;
    },
    fetchHouseholdsSuccess(state, action: PayloadAction<HouseholdLoaded>) {
      const { households } = action.payload;
      state.households = households;
      state.error = null;
      state.loading = false;
    },
    fetchHouseholdsFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchHouseholdsSuccess,
  fetchHouseholdsStart,
  fetchHouseholdsFailure,
  resetError,
} = household.actions;
export default household.reducer;

export const fetchHouseholds = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchHouseholdsStart());
    const res = await fetch();
    dispatch(
      fetchHouseholdsSuccess({
        households: res.data.data.households,
      })
    );
  } catch (err: any) {
    dispatch(fetchHouseholdsFailure(err));
    throw err;
  }
};
