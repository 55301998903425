import React, {
  useState,
  useEffect,
  ChangeEvent,
  createRef,
  useRef,
} from 'react';
import { MessageModel } from 'api/model/message';
import { useForm } from 'react-hook-form';
import './MessageForm.scss';
import { isValidType } from '../../../../libs/filetype';
import { INVALID_FILE_TYPE_MESSAGE } from 'libs/constants';
import { getThumbnail } from 'libs/getThumbnail';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSubmit: (params: FormData) => {},
  triggerSubmit: false,
  inputValue: '',
};

type Props = {
  onSubmit: (params: FormData) => void;
  checkFormState: (isValidState: boolean) => void;
  characterCountState: (isExceed: boolean) => void;
  triggerSubmit: boolean;
  onInputChange?: (newValue: string) => void;
  selectedMessage?: MessageModel | null;
  inputValue: string;
  isSP: boolean;
};

export type FileData = {
  fileId?: number;
  previewUrl: string;
  file?: File;
};

export type FormData = {
  messageId?: number;
  body: string;
  files: FileData[];
};

export default function MessageForm(props: Props) {
  const {
    onSubmit,
    checkFormState,
    characterCountState,
    triggerSubmit,
    onInputChange = () => {},
    selectedMessage,
    inputValue,
    isSP,
  } = props;

  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const {
    register,
    formState,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    getValues,
    setValue,
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [files, setFiles] = useState<FileData[]>([]);
  const [showToolbar, setShowToolbar] = useState(false);

  const MAX_FILES = 10;
  const MAX_BODY_LENGTH = 2000;

  const ref = createRef<HTMLInputElement>();
  const quillRef = useRef<ReactQuill>(null);

  const handleFileOpen = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  // 改行・半角/全角スペースのみでテキストが構成されるているかチェック
  const isNotEmptyField = (inputText: string) => {
    const charCodes = [];
    const invalidCharCode = new Set([10, 32, 12288]); // 改行 / 半角スペース / 全角スペース
    for (let i = 0; i < inputText.length; i++) {
      charCodes.push(inputText.charCodeAt(i));
    }
    return !charCodes.every((char) => invalidCharCode.has(char));
  };
  // <xxx>, </xxx>(HTMLタグ), 空白文字のみでHTMLが構成されるかチェック
  const isNotEmptyHtml = (inputHTML: string) => {
    const pattern = /^(<[^>]+>|<\/[^>]+>|\s+)*$/;
    return !pattern.test(inputHTML);
  };

  const handleInputChange = (html: string) => {
    const editor = quillRef.current?.getEditor();
    const text = editor?.getText() || '';
    const isValid = isNotEmptyField(text) || files.length !== 0;
    onInputChange(html);
    checkFormState(isValid);

    if (text.length <= MAX_BODY_LENGTH) {
      clearErrors('body');
    } else {
      characterCountState(true);
    }

    if (!isValid) {
      setError('body', { type: 'error' });
    }
  };

  useEffect(() => {
    if (selectedMessage) {
      if (selectedMessage.message.id) {
        setValue('messageId', selectedMessage.message.id);
      }
      if (selectedMessage.message.body) {
        setValue('body', selectedMessage.message.body);
      }
      if (selectedMessage.message.data_files.length > 0) {
        setFiles(
          selectedMessage.message.data_files.map((file) => ({
            fileId: file.id,
            previewUrl: file.url,
          }))
        );
      }
    }
  }, [selectedMessage]);

  useEffect(() => {
    if (triggerSubmit) {
      const values = getValues();
      values.body = inputValue;
      values.files = files;
      setFiles([]);
      onSubmit(values);
      reset();
    }
  }, [triggerSubmit]);

  useEffect(() => {
    if (files.length > 0) {
      checkFormState(true);
      return;
    }
    const isValid = isNotEmptyHtml(inputValue) || files.length !== 0;
    checkFormState(isValid);
  }, [formState]);

  const handleFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      return;
    }

    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length + files.length > 10) {
      setError('files', {
        type: 'error',
        message: '添付ファイルは最大10個までです',
      });
      setFileErrorMessage('添付ファイルは最大10個までです');
      return;
    }

    selectedFiles.forEach((file) => {
      if (file === null) {
        return;
      }
      if (file.size >= 20000000) {
        setError('files', {
          type: 'error',
          message: '20MB以下の画像をアップロードしてください',
        });
        setFileErrorMessage('20MB以下の画像をアップロードしてください');
        return;
      }
      if (!isValidType(file.type)) {
        setError('files', {
          type: 'error',
          message: INVALID_FILE_TYPE_MESSAGE,
        });
        setFileErrorMessage(INVALID_FILE_TYPE_MESSAGE);
        return;
      }
      clearErrors('files');
      clearErrors('body');
      setFiles((prevState) => [
        ...prevState,
        { previewUrl: URL.createObjectURL(file), file: file },
      ]);
    });
  };

  const defaultToolbar = [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ size: [false, 'large'] }],
    ['link'],
    [{ color: ['red', 'blue', 'green', 'white', 'black'] }],
  ];

  const spToolbar = [...defaultToolbar, [{ list: 'bullet' }]];

  const pcToolbar = [
    ...defaultToolbar,
    [
      {
        background: [
          'red',
          'blue',
          'green',
          'white',
          'black',
          'aqua',
          'yellow',
        ],
      },
    ],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ];

  const modules = {
    toolbar: {
      container: isSP ? spToolbar : pcToolbar,
    },
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'size',
    'list',
    'bullet',
    'color',
    'link',
    'background',
  ];

  const toggleToolbar = () => {
    setShowToolbar(!showToolbar);
  };

  return (
    <div className="MessageForm">
      <form>
        <input {...register('messageId', {})} name="messageId" type="hidden" />
        <div className="MessageForm__input">
          <ReactQuill
            className={isSP ? (showToolbar ? '' : 'hidden-toolbar') : ''}
            theme="snow"
            placeholder="投稿者名、メッセージを入力してください。"
            modules={modules}
            formats={formats}
            ref={quillRef}
            onChange={handleInputChange}
            value={inputValue}
          />
          <ul className="CreateMessage__media-list">
            {files.map((file) => (
              <li
                key={file.previewUrl}
                className={`${
                  file.file?.type === 'application/pdf'
                    ? '-pdf -media'
                    : '-media'
                }`}
              >
                <span
                  onClick={() => {
                    setFiles(
                      files.filter((f) => f.previewUrl !== file.previewUrl)
                    );
                    // 削除したファイルを再度選択できるようにする
                    if (ref.current) ref.current.value = '';
                  }}
                  className="delete-btn"
                />
                {file.file?.type !== 'application/pdf' ? (
                  <img
                    src={getThumbnail(file.file?.name ?? '', file.previewUrl)}
                    alt={file.file?.name}
                  />
                ) : (
                  <p>{file.file.name}</p>
                )}
              </li>
            ))}
            {!isSP && files.length < MAX_FILES && (
              <>
                <li className="add-media">
                  <span onClick={handleFileOpen} />
                </li>
                <input
                  hidden
                  id="fileUpload"
                  type="file"
                  multiple
                  ref={ref}
                  onChange={(e) => handleFiles(e)}
                />
              </>
            )}
          </ul>
        </div>
      </form>

      <div className="MessageForm__error">
        {errors.body && errors.files && <br />}
        {errors.files && fileErrorMessage}
      </div>
      {isSP && files.length < MAX_FILES && (
        <>
          <div className="add-media-sp">
            <span onClick={handleFileOpen} />
          </div>
          <input
            hidden
            id="fileUpload"
            type="file"
            multiple
            ref={ref}
            onChange={(e) => handleFiles(e)}
          />
        </>
      )}
      {isSP && (
        <div className="toggle-toolbar">
          <span
            onClick={toggleToolbar}
            className={`toggle-toolbar__icon ${
              showToolbar ? '-close' : '-open'
            }`}
          ></span>
        </div>
      )}
    </div>
  );
}

MessageForm.defaultProps = defaultProps;
