import { format, compareDesc, formatISO } from 'date-fns';
import { ja } from 'date-fns/locale';

export enum DayNumber {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  NEXT_SUNDAY = 7,
}

export function date(str: string): Date {
  return new Date(str);
}

export function dateString(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

export function datetimeStringWithSlash(str: string): string {
  return format(new Date(str), 'yyyy/MM/dd HH:mm', { locale: ja });
}

export function datetimeStringWithSlashAndSeconds(str: string): string {
  return format(new Date(str), 'yyyy/MM/dd HH:mm:ss', { locale: ja });
}

export function datetimeStringJp(str: string): string {
  return format(new Date(str), 'HH:mm', { locale: ja });
}

export function dateStringJp(str: string): string {
  return format(new Date(str), 'yyyy年M月d日 (EE)', { locale: ja });
}

export function shortdateStringJp(str: string): string {
  return format(new Date(str), 'M月d日 (EE)', { locale: ja });
}

export function onlyDateStringJp(str: string): string {
  return format(new Date(str), 'M月d日', { locale: ja });
}

export function yearString(str: string): string {
  return format(new Date(str), 'yyyy', { locale: ja });
}

export function yeardateStringJp(str: string): string {
  return format(new Date(str), 'yyyy年M月d日', { locale: ja });
}

export function isOver(date1: string, date2: string): boolean {
  const ret = compareDesc(new Date(date1), new Date(date2));
  return ret >= 0;
}

// 1900年でデータが返ってくるパターンがあるので判別するためのロジック
export function isNullValue(dateString: string): boolean {
  return new Date(dateString).getFullYear() === 1900;
}

// 既存の日時変換関数と重複していないか確認
export function dateToISOString(date: Date): string {
  return formatISO(date);
}

export const dateOfThisWeek = (date: Date): string => {
  return (
    dateStringJp(thisDayOfTheWeek(date, DayNumber.MONDAY).toDateString()) +
    ' ～ ' +
    dateStringJp(thisDayOfTheWeek(date, DayNumber.NEXT_SUNDAY).toDateString())
  );
};

export const thisDayOfTheWeek = (date: Date, dayNumber: DayNumber): Date => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay() + dayNumber
  );
};
