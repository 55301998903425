import React, { useState } from 'react';
import { FurnitureModel } from 'api/model/furniture';
import './FurnitureSlide.scss';

type Props = {
  furniture?: FurnitureModel;
  onClose?: () => void;
  imageIndex: number;
};

export const FurnitureSlide = (props: Props) => {
  const { furniture, onClose, imageIndex } = props;
  const [currentIndex, setCurrentIndex] = useState(imageIndex);

  const InputTag = furniture?.images.map((_, index) => (
    <input
      type="radio"
      name="slideshow"
      id={`switch${currentIndex + 1}`}
      key={index + 1}
      checked={index === currentIndex}
      onChange={() => setCurrentIndex(currentIndex)}
    />
  ));

  // 前後の画像に切り替える
  const handlePrev = () => {
    const prevIndex = currentIndex - 1;
    setCurrentIndex(prevIndex);
  };
  const handleNext = () => {
    const nextIndex = currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  // 前後の画像が存在するか確認
  const isPreviousImageExist = () => furniture && currentIndex - 1 >= 0;
  const isNextImageExist = () =>
    furniture && currentIndex + 1 < furniture.images.length;

  return (
    <div id="FurnitureSlide">
      <button className="FurnitureSlide__btn" onClick={onClose}>
        閉じる
      </button>
      <div className="slideshow">
        {InputTag}
        <div className="slideContents">
          {furniture?.images.map((_, index) => (
            <section id={`slide${currentIndex + 1}`} key={index}>
              <img
                src={furniture.images[currentIndex].url}
                alt="家具家電画像"
              />

              <div className="FurnitureSlide__count-wrap">
                <p className="FurnitureSlide__count-text">{`${
                  currentIndex + 1
                }/${furniture.images.length}`}</p>
              </div>
            </section>
          ))}
        </div>
        {isPreviousImageExist() && (
          <p className="arrow prev" onClick={handlePrev}>
            <i className="ico" />
          </p>
        )}
        {isNextImageExist() && (
          <p className="arrow next" onClick={handleNext}>
            <i className="ico" />
          </p>
        )}
      </div>
    </div>
  );
};

export default FurnitureSlide;
