import React, { useState } from 'react';
import ModalBase from '../../atoms/ModalBase';
import CloseIcon from '@material-ui/icons/Close';
import { ArticleModel } from 'api/model/article';

const defaultProps = {};

export type ModalProps = {
  article: ArticleModel;
  open: boolean;
  setOpenColumnReviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  userId?: number;
  onSubmitArticleReview: (
    reviewTargetId: number,
    comment: string | null,
    constructionId: number
  ) => void;
};

export default function ColumnReviewModal(props: ModalProps) {
  const [comment, setComment] = useState('');
  const [completedReview, setCompletedReview] = useState(false);
  const {
    article,
    open,
    setOpenColumnReviewModal,
    userId,
    onSubmitArticleReview,
  } = props;

  const handleClose = () => {
    setComment('');
    setCompletedReview(false);
    setOpenColumnReviewModal(false);
  };

  const sendReview = () => {
    if (userId && comment) {
      onSubmitArticleReview(article.id, comment, userId);
      setCompletedReview(true);
    }
  };

  if (completedReview) {
    return (
      <ModalBase onClose={handleClose} open={open} className="Modal">
        <div className="Modal -review-modal">
          <div className="Modal__content">
            <div className="Modal__nav">
              <p className="Modal__title">ご回答ありがとうございます</p>
            </div>
            <div className="Modal__main">
              <div className="review-line" />
              <div>
                <button className="not-review" onClick={() => handleClose()}>
                  閉じる
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBase>
    );
  } else {
    return (
      <ModalBase
        onClose={handleClose}
        open={open}
        className="Modal"
        disableBackdropClick
      >
        <div className="Modal -column-review-modal">
          <div className="Modal__content">
            <button className="close-button" onClick={handleClose}>
              <CloseIcon />
            </button>

            <div className="column-modal-title">
              <p>このコラムの改善要望を送信</p>
            </div>

            <div>
              <textarea
                className="column-textarea"
                value={comment}
                placeholder="要望記入欄"
                maxLength={2000}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div className="example-container">
              <p className="column-example">
                例：●●が分かりづらいので●●してほしい
              </p>
              <p className="column-example">
                例：●●についても詳しく説明があると良い
              </p>
            </div>

            <div className={`review-line send-review`}>
              <span
                className={`send-button ${comment ? '-on' : ''}`}
                onClick={() => sendReview()}
              >
                送信
              </span>
            </div>
          </div>
        </div>
      </ModalBase>
    );
  }
}

ColumnReviewModal.defaultProps = defaultProps;
