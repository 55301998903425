import React, { useState, ReactNode } from 'react';
import { DataFileModel } from '../../../api/model/dataFile';
import A from '../../atoms/A';
import PageTitle from '../../atoms/PageTitle';
import { CatalogModel } from '../../../api/model/catalog';
import { DOWNLOAD_FILE_EXTENSIONS } from 'libs/constants';
import { downloadFileFromURL } from 'libs/downloadFileFromUrl';
import { getThumbnail } from 'libs/getThumbnail';

const defaultProps = {
  dataFiles: [],
};

type Props = {
  children?: ReactNode;
  dataFiles: DataFileModel[];
  catalogs: CatalogModel[];
  slideOpen: boolean;
  handleFileType: (fileType: string) => void;
  handleCatalogs: () => void;
} & typeof defaultProps;

const folderTypes = [
  { folder_type: ['product'], folder_name: '商品実例カタログ' },
  {
    folder_type: ['this_year_material', 'last_year_material'],
    folder_name: '部資材カタログ',
  },
  { folder_type: ['other'], folder_name: 'その他' },
];

const handleFileClick = (
  url: string,
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  filename: string
) => {
  if (event.target) {
    event.preventDefault();
  }
  if (url.includes('.glb')) {
    event.preventDefault(); // デフォルトのリンククリック動作を停止
    localStorage.setItem('three_d_file_url', url);
    const viewerURL = '/three_d_viewer';
    window.open(viewerURL, '_blank', 'noreferrer'); // 別タブで3DファイルのURLを開く
  } else if (
    DOWNLOAD_FILE_EXTENSIONS.some((extension) => filename.includes(extension))
  ) {
    downloadFileFromURL(url, filename);
  } else {
    window.open(url, '_blank', 'noreferrer');
  }
};

export default function Files(props: Props) {
  const { dataFiles, catalogs, slideOpen, handleFileType, handleCatalogs } =
    props;
  const [currentTab, setCurrentTab] = useState('/appointments');
  const [subMenuVisible, setSubMenuVisible] = useState(true);

  const onClick = (path: string) => {
    if (path === '/collection') {
      handleCatalogs();
      setSubMenuVisible(false);
    } else {
      handleFileType(path.replace('/', ''));
      setSubMenuVisible(true);
    }
    setCurrentTab(path);
  };

  const displayFiles = () => {
    return dataFiles.map((file) => {
      return (
        <li key={file.id}>
          <A
            href={file.url}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleFileClick(file.url, event, file.name)
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={getThumbnail(file.url, file.thumb_url)} alt={file.name} />
          </A>

          <span className="FileView__item-name">
            {file.name}
            {file.is_updated_within_24_hours && (
              <span className="FileView__item-badge">●</span>
            )}
          </span>
        </li>
      );
    });
  };

  const displayCollection = () => {
    return folderTypes.map((folder, index) => {
      const list = catalogs.filter((c) =>
        folder.folder_type.includes(c.folder_type)
      );
      if (!list.length) return null;
      return (
        <div className="Media__collection" key={index}>
          <h2>{folder.folder_name}</h2>
          <ul className="Media__list-files">
            {list.map((file) => (
              <li key={file.id}>
                <A href={file.pdf_url} target="_blank">
                  <img
                    className="FileView__item-img"
                    src={file.thumb_url}
                    alt={file.name}
                  />
                </A>
                <span className="FileView__item-name">{file.name}</span>
              </li>
            ))}
          </ul>
        </div>
      );
    });
  };

  return (
    <>
      <PageTitle>ファイル</PageTitle>
      <div className={`Tab -files ${slideOpen ? '-slide-open' : ''}`}>
        <ul className="Tab__list">
          <li
            onClick={() => !subMenuVisible && onClick('/appointments')}
            className={subMenuVisible ? '--open-sub-menu' : ''}
          >
            <span className="Tab__name">保存資料</span>
            <ul className="SubTab">
              <li
                className={`${currentTab === '/appointments' && '-current'}`}
                onClick={() => onClick('/appointments')}
              >
                <span className="Tab__name">打合せ資料</span>
              </li>
              <li
                className={`${currentTab === '/users' && '-current'}`}
                onClick={() => onClick('/users')}
              >
                <span className="Tab__name">お客様提供</span>
              </li>
              <li
                className={`${currentTab === '/others' && '-current'}`}
                onClick={() => onClick('/others')}
              >
                <span className="Tab__name">その他</span>
              </li>
            </ul>
          </li>
          <li
            className={`${currentTab === '/collection' && '-current'}`}
            onClick={() => onClick('/collection')}
          >
            <span className="Tab__name">参考集</span>
          </li>
        </ul>
      </div>
      <div id="pageTop" className="container -with-tab">
        <div className="Media__root-files">
          <div className="Media__box Media__box-files">
            <div className="Media__box-inner-files">
              {currentTab === '/collection' ? (
                displayCollection()
              ) : (
                <ul className="Media__list-files">{displayFiles()}</ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
