import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import { TaskModel } from '../../../api/model/task';
import { shortdateStringJp } from '../../../libs/date';
import './TaskCard.scss';
import { LightTooltip } from 'components/atoms/LightToolTip';
import { useNavigate } from 'react-router';
import { SortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

export interface ITaskCardProps {}

const defaultProps = {
  task: {},
  isSortable: false,
};

export type Props = {
  task: TaskModel;
  onClick?: () => void;
  selectedTaskId?: number;
  isSortable: boolean;
} & Omit<typeof defaultProps, 'task'>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: { task: TaskModel; selectedTaskId?: number }) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 275,
      width: '100%',
      marginBottom: 10,
      [theme.breakpoints.down('md')]: {
        marginBottom: 5,
      },
      border: determineBorderStyle(props.task.id, props.selectedTaskId),
      borderRadius: 9,
      filter: props.task.trashed ? 'opacity(50%)' : 'none',
    }),
    sortHandle: {
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#d3d3d3',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '12px',
      '&.MuiCardContent-root:last-child': {
        paddingBottom: '12px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '6px',
        '&.MuiCardContent-root:last-child': {
          paddingBottom: '6px',
        },
      },
    },
    title: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    completed: {
      textDecoration: 'line-through',
    },
    date: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    typography: {
      padding: theme.spacing(2),
      fontSize: 14,
      maxWidth: '400px',
      wordWrap: 'break-word',
    },
  })
);

const determineBorderStyle = (taskId: number, selectedTaskId?: number) => {
  if (taskId === selectedTaskId) {
    return '1.5px solid #02A4FF';
  }
  return '1.5px solid #d6d6d6';
};

export default function TaskCard(props: Props) {
  const { task, onClick, selectedTaskId, isSortable } = props;
  const classes = useStyles({
    task: task,
    selectedTaskId: selectedTaskId,
  });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleDetailClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // 親イベントが親要素に伝わるのを防御
    setAnchorEl(event.currentTarget);
  };

  const handleDetailClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // 親イベントが親要素に伝わるのを防御
    setAnchorEl(null);
  };

  const handleMaterialCheckClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation(); // 親イベントが親要素に伝わるのを防御
    navigate('/materials');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getPrioritySpanClass = (priority: string) => {
    switch (priority) {
      case 'low':
        return 'low';
      case 'middle':
        return 'middle';
      case 'high':
        return 'high';
      default:
        return 'unset';
    }
  };

  const displaySharedStatusHistory = (shared_status: string): string => {
    switch (shared_status) {
      case 'draft':
        return '下書き';
      case 'private_use':
        return '非公開';
      case 'public_use':
        return '公開';
      default:
        return '';
    }
  };

  // textのurlをリンクに変換し、改行コードによって改行するようコンバートする関数
  const convertTextToLinksWithNewlines = (text: string) => {
    if (!text) return '';

    const urlRegex =
      /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#%=~_|])/gi;
    // 改行コードでスプリットした配列をmap関数で回し、urlRegexの正規表現とマッチすればaタグにする
    return text.split('\n').map((line, index) => (
      <span key={index}>
        {line.split(urlRegex).map((part, i) =>
          urlRegex.test(part) ? (
            <a
              key={i}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'blue' }}
              onClick={(e) => e.stopPropagation()}
            >
              {part}
            </a>
          ) : (
            part
          )
        )}
        {/* 最後の行の場合、改行しない */}
        {index < text.split('\n').length - 1 && <br />}
      </span>
    ));
  };

  const DragHandle = SortableHandle(() => (
    <div className={`${classes.sortHandle}`}>
      <DragIndicatorIcon fontSize="large" color="action" />
    </div>
  ));

  return (
    <Card className={`${classes.root} TaskCard`} variant="outlined">
      {isSortable && <DragHandle />}
      <div className="TaskCard__checkbox">
        <label>
          <input
            type="checkbox"
            readOnly
            checked={task.progress_status === 'completed'}
          />
          <span className={getPrioritySpanClass(task.priority)} />
        </label>
      </div>
      <CardContent
        className={`${classes.content} TaskCard__content`}
        onClick={onClick}
      >
        <div className="card-top">
          {!task.can_edit && (
            <LightTooltip title="編集権限なし">
              <img
                className="no-edit-icon"
                src="/assets/img/tasks/icon/no_edit.svg"
                alt=""
              />
            </LightTooltip>
          )}

          <Typography
            className={`${classes.title} ${
              task.progress_status === 'completed' ? classes.completed : ''
            }`}
            color="textSecondary"
            gutterBottom
          >
            {task.title}
          </Typography>
          {task.is_material_task && (
            <button
              onClick={(event) => {
                handleMaterialCheckClick(event);
              }}
            >
              <span>確認ページはこちら</span>
            </button>
          )}
        </div>
        <div className="TaskCard__content--lower">
          <Typography className={classes.date} color="textSecondary">
            {`締切: ${
              task.due_date ? shortdateStringJp(task.due_date) : '未入力'
            }`}
          </Typography>
          <LightTooltip title={displaySharedStatusHistory(task.shared_status)}>
            <img
              className="no-pointer-img"
              src={`/assets/img/tasks/icon/${task.shared_status}.svg`}
              alt=""
            />
          </LightTooltip>
          {task.detail && (
            <LightTooltip title="メモ">
              <button
                aria-describedby={id}
                color="primary"
                onClick={(event) => {
                  handleDetailClick(event);
                }}
              >
                <img
                  src="/assets/img/tasks/icon/task_detail.svg"
                  alt="タスク詳細"
                />
              </button>
            </LightTooltip>
          )}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleDetailClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography className={classes.typography}>
              {convertTextToLinksWithNewlines(task.detail)}
            </Typography>
          </Popover>
        </div>
      </CardContent>
    </Card>
  );
}
