import { apiClient } from './client';
import { FurnitureModel } from './model/furniture';

export type FetchParams = {
  householdId?: number | null;
  isDefaultHousehold?: boolean | null;
};

export type FetchRes = {
  data: {
    design_approval_completion: boolean;
    furnitures: FurnitureModel[];
  };
};

export async function fetch() {
  return apiClient.get<FetchRes>(`/furnitures`);
}

export async function selectedFetch(params: FetchParams) {
  return apiClient.get<FetchRes>(`/furnitures`, {
    params: {
      household_id: params.householdId === 0 ? null : params.householdId,
      is_default_household: params.isDefaultHousehold,
    },
  });
}

export type ImageParams = {
  id?: number;
  name?: string;
  url?: number;
  image?: File;
};

export type CreateParams = {
  // number, boolean型もstringで送られてくるためstring指定
  name: string;
  width: string;
  depth: string;
  height: string;
  place: string;
  purchase: string;
  memo: string;
  files?: ImageParams[];
  householdId: number | null;
};

export type CreateRes = {
  data: {
    furniture: FurnitureModel;
  };
};

export async function create(params: CreateParams) {
  // number, File型を許容するため一旦anyとする
  /* eslint-disable */
  const formData: any = new FormData();

  formData.append('name', params.name);
  formData.append('width', params.width);
  formData.append('depth', params.depth);
  formData.append('height', params.height);
  formData.append('place', params.place);
  formData.append('purchase', params.purchase);
  formData.append('memo', params.memo);
  params.files?.map((file) => {
    Object.entries(file).forEach(([key, value]) => {
      formData.append('files[]' + key, value);
    });
  });
  return apiClient.post<CreateRes>(`/furnitures`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      household_id: params.householdId,
    },
  });
}

export type UpdateParams = {
  // number, boolean型もstringで送られてくるためstring指定
  furnitureId: number;
  name: string;
  width: string;
  depth: string;
  height: string;
  place: string;
  purchase: string;
  memo: string;
  files?: ImageParams[];
  householdId: number | null;
};

export type UpdateRes = {
  data: {
    furniture: FurnitureModel;
  };
};

export async function update(params: UpdateParams) {
  // number, File型を許容するため一旦anyとする
  /* eslint-disable */
  const formData: any = new FormData();

  formData.append('name', params.name);
  formData.append('width', params.width);
  formData.append('depth', params.depth);
  formData.append('height', params.height);
  formData.append('place', params.place);
  formData.append('purchase', params.purchase);
  formData.append('memo', params.memo);
  params.files?.map((file) => {
    Object.entries(file).forEach(([key, value]) => {
      formData.append('files[]' + key, value);
    });
  });
  return apiClient.patch<UpdateRes>(
    `/furnitures/${params.furnitureId}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: {
        household_id: params.householdId,
      },
    }
  );
}

export type DestroyParams = {
  furnitureId: number;
};

export type DestroyRes = {
  data: {
    furnitures: FurnitureModel[];
  };
};

export async function destroy(params: DestroyParams) {
  return apiClient.delete<DestroyRes>(`/furnitures/${params.furnitureId}`);
}
