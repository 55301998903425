import React, { useEffect, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { useLocation } from 'react-router';
import { generateToken } from '../../../libs/token';
import { loginUser } from '../../user/userSlice';
import * as Sentry from '@sentry/browser';
import Auth from '../../../components/atoms/Auth';

type Props = {
  children?: ReactNode;
};

export default function ConnectedAuth(props: Props) {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const [redirect, setRedirect] = useState(false);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const deletedUser = useSelector((state: RootState) => state.user.deletedUser);

  const csrfToken = generateToken();
  const receivedCsrfToken = new URLSearchParams(location.search).get(
    'csrf_token'
  );
  const keyCsrf = 'navi_token';
  const keySsoRedirect = 'is_sso_redirect';
  const setCsrfToken = localStorage.getItem(keyCsrf);
  const isSsoRedirect = localStorage.getItem(keySsoRedirect);

  useEffect(() => {
    async function f() {
      try {
        // csrfトークンエラー
        if (
          receivedCsrfToken &&
          setCsrfToken &&
          receivedCsrfToken !== setCsrfToken
        ) {
          handleSsoFailure(true, deletedUser);
          resetRedirectValues();
        } else {
          await dispatch(loginUser());
          resetRedirectValues();
        }
      } catch (error) {
        // Sentry.captureException(error);
        if (isSsoRedirect && isSsoRedirect === 'true' && receivedCsrfToken) {
          handleSsoFailure(false, deletedUser);
          resetRedirectValues();
        } else {
          setRedirectValues();
          setRedirect(true);
        }
      }
    }
    f();
  }, [dispatch, loggedIn, deletedUser]);

  const setRedirectValues = () => {
    localStorage.setItem(keyCsrf, csrfToken);
    localStorage.setItem(keySsoRedirect, 'true');
  };

  const resetRedirectValues = () => {
    localStorage.setItem(keyCsrf, '');
    localStorage.setItem(keySsoRedirect, 'false');
  };

  const getErrorMessage = (csrfError: boolean, deletedUser: boolean) => {
    if (csrfError) {
      return '不正な遷移です。再度操作を行なってください。';
    } else if (deletedUser) {
      return '住まいづくりナビは利用終了しました。ご利用ありがとうございました。今後は、三井ホーム倶楽部をご活用ください。';
    } else {
      return 'アクセスに失敗しました。再度操作を行なってください。';
    }
  };

  const handleSsoFailure = (csrfError: boolean, deletedUser: boolean) => {
    const message = getErrorMessage(csrfError, deletedUser);
    dispatch({
      type: 'user/ssoFailure',
      payload: { message },
    });
  };

  const { children } = props;
  return (
    <>
      <Auth
        prevUrl={location.pathname}
        csrfToken={setCsrfToken || csrfToken}
        login={loggedIn}
        redirect={redirect}
      >
        {children}
      </Auth>
    </>
  );
}
