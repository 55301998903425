import { apiClient } from './client';
import { NotificationSettingModel } from './model/notificationSetting';

export type FetchRes = {
  data: {
    user_emails: NotificationSettingModel[];
  };
};

export async function fetch() {
  return apiClient.get<FetchRes>(`/user_emails`);
}

export type ToggleParams = {
  userEmailId: number;
  mailType: string;
};

export type ToggleRes = {
  data: {
    user: NotificationSettingModel;
  };
};

export async function toggle(params: ToggleParams) {
  const body = {
    user_email_id: params.userEmailId,
    mail_type: params.mailType,
  };
  return apiClient.patch<ToggleRes>(`/mail_settings`, body);
}
