import React from 'react';
import Sso from '../Sso';

const defaultProps = {
  login: false,
  redirect: false,
  csrfToken: '',
  prevUrl: '',
} as Partial<Props>;

type Props = {
  children?: any;
  login: boolean;
  redirect: boolean;
  csrfToken: string;
  prevUrl: string;
};

const Auth = (props: Props) => (
  <>
    {props.login ? (
      props.children
    ) : (
      <Sso
        redirect={props.redirect}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
        csrfToken={props.csrfToken}
        prevUrl={`${process.env.REACT_APP_WEB_HOST}${props.prevUrl}`}
      />
    )}
  </>
);

Auth.defaultProps = defaultProps;

export default Auth;
