export function isValidType(type: string): boolean {
  return [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ].includes(type);
}

export function isValidFurnitureType(type: string): boolean {
  return ['image/jpeg', 'image/png', 'image/gif'].includes(type);
}
