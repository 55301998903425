import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { useParams } from 'react-router-dom';
import { getAgenda } from '../agendaSlice';
import * as Sentry from '@sentry/browser';
import ScheduleArticles from '../../../components/pages/ScheduleArticles';

type Props = {
  children?: ReactNode;
};

export default function ScheduleArticlesWithRedux(props: Props) {
  const dispatch = useDispatch<any>();
  const { id } = useParams();

  // TODO: as any良くない
  const agenda = useSelector((state: RootState) => state.agenda.agenda) as any;
  const fetching = useSelector((state: RootState) => state.agenda.fetching);

  useEffect(() => {
    async function f() {
      try {
        await dispatch(getAgenda(Number(id)));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
    // eslint-disable-next-line
  }, [dispatch, id]);

  return (
    <>
      <ScheduleArticles agenda={agenda} fetching={fetching} />
    </>
  );
}
