import React, { ReactNode } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const defaultProps = {
  title: '',
};

type Props = {
  children: ReactNode;
};

export default function PageTitle(props: Props) {
  const { children } = props;
  const matches = useMediaQuery('(min-width:769px)');

  return (
    <>
      {matches && (
        <div className="Bar u-flex-04">
          <h1 className="Bar__title">{children}</h1>
        </div>
      )}
    </>
  );
}

PageTitle.defaultProps = defaultProps;
