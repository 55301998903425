import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { LightTooltip } from '../LightToolTip';

const defaultProps = {
  textClass: '',
  ulClass: '',
  liClass: '',
  current: '',
  unreadMessageCount: 0,
  items: [],
  onClick: () => {},
};

export type Props = {
  TabBaseType?: string;
  current?: string;
  unreadMessageCount?: number;
  ignoreVersionMaster?: boolean;
  onClick: (item: Item) => void;
  items: Item[];
  children?: ReactNode;
} & Omit<typeof defaultProps, 'items' | 'onClick'>;

export type Item = {
  id: string;
  path: string;
  name?: string;
  class: string;
};

// TODO: PageName を変更
export default function NavBase(props: Props) {
  const {
    items,
    onClick,
    current,
    unreadMessageCount,
    ignoreVersionMaster,
    ulClass,
    liClass,
    textClass,
  } = props;

  const nonDisplayItemClass = (item: Item) =>
    ignoreVersionMaster &&
    ['checkItem', 'column', 'furniture'].includes(item.id)
      ? '-nonDisplay'
      : '';

  return (
    <ul className={ulClass}>
      {items.map((item: Item) => {
        const key = item.id;
        return item.id === 'tasks' || item.id === 'checkItem' ? (
          <LightTooltip
            title={item.id === 'tasks' ? '新機能' : '旧TODO'}
            key={key}
          >
            <li
              className={classNames(
                liClass,
                { [item.class]: item.class },
                {
                  '-current':
                    item.path !== '/'
                      ? current.includes(item.path)
                      : item.path === current,
                },
                nonDisplayItemClass(item)
              )}
              onClick={() => onClick(item)}
            >
              {item.name && (
                <span className={classNames(textClass, item.class)}>
                  {item.name}
                </span>
              )}
            </li>
          </LightTooltip>
        ) : (
          <li
            key={key}
            className={classNames(
              liClass,
              { [item.class]: item.class },
              {
                '-current':
                  item.path !== '/'
                    ? current.includes(item.path) ||
                      (item.id === 'schedule' &&
                        current.includes('/appointments'))
                    : item.path === current,
              },
              nonDisplayItemClass(item)
            )}
            onClick={() => onClick(item)}
          >
            {item.name && (
              <span className={classNames(textClass, item.class)}>
                {item.name}
              </span>
            )}
            {item.id == 'messages' && unreadMessageCount !== 0 && (
              <span className="Nav__message-notification-num">
                {unreadMessageCount < 100 ? unreadMessageCount : 99}
              </span>
            )}
          </li>
        );
      })}
    </ul>
  );
}

// TODO: PageName を変更
NavBase.defaultProps = defaultProps;
