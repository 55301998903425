import { apiClient } from './client';
import { SystemMessageModel } from './model/systemMessage';

export type fetchSystemMessageParams = {
  filterType: string;
};

export type FetchRes = {
  data: { system_messages: SystemMessageModel[] };
};

export async function fetch(params: fetchSystemMessageParams) {
  return apiClient.get<FetchRes>(
    `/system_messages?filter_type=${params.filterType}`
  );
}
