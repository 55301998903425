import React, { useEffect, ReactNode, useRef } from 'react';
import { MaterialModel } from '../../../api/model/material';
import A from '../../atoms/A';
import Nav from '../../molecules/Nav';
import Skeleton from 'components/atoms/Skeleton';

const defaultProps = {
  fetching: true,
};

type Props = {
  material: MaterialModel;
  children?: ReactNode;
  fetching: boolean;
} & typeof defaultProps;

export default function Materials(props: Props) {
  const { material, fetching } = props;
  const imageEl = useRef<HTMLImageElement>(null);

  // HACK: 画像のちらつきを抑える
  // とりあえずの回避策
  useEffect(() => {
    if (imageEl.current && material.image) imageEl.current.src = material.image;
    return () => {
      if (imageEl.current) imageEl.current.src = '';
    };
  });

  return (
    <>
      <Nav title="部資材仕様特性のご案内" />
      <div id="pageTop" className="container -with-bar">
        <div className="Media__root-column">
          {fetching && <Skeleton type="scheduleArticle" />}
          {!fetching && (
            <div>
              {material.image !== '' && (
                <>
                  <div className="Media__kv-column">
                    <img
                      alt=""
                      className="Media__kv-column-image"
                      src={material.image}
                    />
                  </div>
                  <div className="Media__text">
                    この「部資材仕様特性のご案内」は、間取り・設備・仕上げ・取扱いに関して、
                    ご注意いただく事項や、お客様からのご質問が多い事柄を記載し、あらかじめご確認、
                    ご承知おきいただくために作成されております。
                    ご入居された後の生活を快適にお送りいただくために必要な内容も含まれておりますので、
                    十分ご理解いただきますようお願い申しあげます。間取り・設備・仕上げ等に影響する内容のため、
                    設計完了承諾までに内容のご確認をお願いいたします。
                  </div>
                </>
              )}
              {material.articles.length === 0 ? (
                <div className="Column__none">
                  <p>確認するコラムはありません</p>
                </div>
              ) : (
                <div className="Media__box Media__box-column">
                  <div className="Media__box-inner-column">
                    <ul className="Media__list-column">
                      {material.articles.map((article) => {
                        return (
                          <li
                            className={`${article.completed ? '-checked' : ''}`}
                            key={article.id}
                          >
                            <A to={`/articles/${article.id}`}>
                              <span>{article.name}</span>
                              <ul className="check-list"></ul>
                            </A>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

Materials.defaultProps = defaultProps;
