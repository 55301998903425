import React, { useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { loginUser } from 'features/user/userSlice';
import { fetchAppointments } from '../appointmentSlice';
import * as Sentry from '@sentry/browser';
import Home from '../../../components/pages/Home';
import { fetchSystemMessage } from '../../message/messageSlice';
import { fetchHouseholds } from 'features/household/householdSlice';
import { AppointmentModel } from 'api/model/appointment';
import { onSendReview } from '../../../features/review/reviewSlice';

type Props = {
  children?: ReactNode;
};

export default function HomeWithRedux(props: Props) {
  const dispatch = useDispatch<any>();

  const appointments = useSelector(
    (state: RootState) => state.appointment.homeAppointments
  ) as AppointmentModel[] & never[];
  const milestone = useSelector(
    (state: RootState) => state.appointment.milestone
  ) as any;
  const user = useSelector((state: RootState) => state.user.currentUser) as any;
  const systemMessages = useSelector(
    (state: RootState) => state.message.systemMessages
  ) as any;
  const hasUsingHouseholds = useSelector(
    (state: RootState) => state.appointment.hasUsingHouseholds
  ) as boolean;

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchAppointments(true));
        await dispatch(fetchSystemMessage());
        await dispatch(fetchHouseholds());
        dispatch(loginUser());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  const nextAppointmentHouseholds =
    appointments.find(
      (appointment) => appointment.appointment_type === 'normal'
    )?.households ?? null;

  const onSubmitReview = async (
    rating: number,
    comment: string | null,
    constructionId: number
  ) => {
    try {
      await dispatch(onSendReview(rating, comment, constructionId));
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Home
        appointments={appointments}
        milestone={milestone}
        user={user}
        systemMessages={systemMessages}
        households={nextAppointmentHouseholds}
        hasUsingHouseholds={hasUsingHouseholds}
        onSubmitReview={(
          rating: number,
          comment: string | null,
          constructionId: number
        ) => onSubmitReview(rating, comment, constructionId)}
      />
    </>
  );
}
