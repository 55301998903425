import React, { ReactNode } from 'react';
import { useMatch } from 'react-router-dom';
import classNames from 'classnames';

const defaultProps = {
  tabType: 'Tab',
  current: '',
  items: [],
  onClick: () => {},
};

export type Props = {
  tabType?: string;
  current?: string;
  onClick: (item: Item) => void;
  items: Item[] | any;
  children?: ReactNode;
};

export type Item = {
  id: string;
  path: string;
  match?: string;
  name?: string;
  itemClass?: string;
  tabs?: Item[];
};

export default function Tab(props: Props) {
  const { items, onClick, tabType, current } = props;
  const handleClassNames = (item: Item) => {
    if (item.path === '') return;
    if (tabType === 'Tab') {
      return { '-current': item.path === current };
    } else {
      return {
        '-current': item.match
          ? useMatch(item.match) !== null
          : useMatch(item.path) !== null,
      };
    }
  };

  return (
    <div className={tabType}>
      <ul className={`${tabType}__list`}>
        {items.map((item: Item, key: number) => (
          <li
            key={key}
            className={`${classNames([
              handleClassNames(item),
              item.itemClass,
            ])}`}
            onClick={() => onClick(item)}
          >
            {item.name && <span className="Tab__name">{item.name}</span>}
          </li>
        ))}
      </ul>
    </div>
  );
}

Tab.defaultProps = defaultProps;
