import React, { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../App/rootReducer';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  children?: ReactNode;
};

export const ErrorWithRedux = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const errorStates: string[] = [];

  const errors = useSelector((state: RootState) => {
    const _errors: any[] = [];

    Object.keys(state).forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (state[key] && state[key].error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        _errors.push(state[key].error);
        errorStates.push(key);
      }
    });
    return _errors;
  });

  const reset = () => {
    errorStates.forEach((key) =>
      dispatch({ type: `${key}/resetError`, payload: '' })
    );
  };

  const switchLoginStatus = () => {
    dispatch({ type: `user/loginUserFailure`, payload: '' });
  };

  const handleClose = () => {
    errorStates.forEach((key) =>
      dispatch({ type: `${key}/resetError`, payload: '' })
    );
  };

  const message = () => {
    try {
      let _messsage = '';
      errors.forEach((error) => {
        // eslint-disable-line no-console
        console.error('error.status', error);
        if (error.response.status === 500 || error.response.status === 404) {
          reset();
          navigate('/error', {
            state: { errorStatus: error.response.status },
          });
        } else if (error.response.status === 401) {
          reset();
          switchLoginStatus();
        } else {
          _messsage += error.response.data.error + '\n';
        }
      });
      return _messsage;
    } catch (error: any) {
      if (location.pathname !== '/error') {
        reset();
        navigate('/error', {
          state: { errorStatus: 500 },
        });
      }
    }
  };

  return (
    <>
      {location.pathname !== '/error' && (
        <Snackbar
          autoHideDuration={3000}
          color="error"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={errors.length > 0}
          onClose={() => handleClose()}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          message={message()}
        />
      )}
    </>
  );
};

export default ErrorWithRedux;
