import React, { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { toggleSlideNav } from '../../user/userSlice';
import Root from '../../../components/templates/Root/index';
import { useLocation } from 'react-router-dom';

const defaultProps = {};

type Props = { children: ReactNode } & typeof defaultProps;

// TODO: PageName を変更
export default function MyRoot(props: Props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const slideOpen = useSelector(
    (state: RootState) => state.user.slideOpen
  ) as any;
  const { children } = props;

  const handleSlideNav = () => {
    dispatch(toggleSlideNav());
  };

  return (
    <Root
      path={location.pathname}
      handleSlideNav={() => handleSlideNav()}
      slideOpen={slideOpen}
    >
      {children}
    </Root>
  );
}

MyRoot.defaultProps = defaultProps;

// TODO: PageName を変更

// const RootNavigator = MyRoot;

// export default RootNavigator;
