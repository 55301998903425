import React, { useState, ReactNode, useEffect } from 'react';
import { TaskModel } from 'api/model/task';
import { FormData as AddFormData } from 'components/molecules/forms/AddTaskForm';
import { FormData } from 'components/molecules/forms/TaskForm';
import AlertDialog from '../../atoms/AlertDialog';
import PageTitle from '../../atoms/PageTitle';
import {
  Theme,
  createStyles,
  makeStyles,
  Drawer,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddTaskForm from 'components/molecules/forms/AddTaskForm';
import TaskDrawer from '../../organisms/TaskDrawer/index';
import { UserModel } from 'api/model/user';
import OriginalSortedTasks from 'components/organisms/OriginalSortedTasks';
import ConditionalSortedTasks from 'components/organisms/ConditionalSortedTasks';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { LockedRecordModel } from 'api/model/lockedRecord';
import { RootState } from 'App/rootReducer';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    accordionRoot: {
      border: 'none',
      boxShadow: 'none',
    },
    accordionDetails: {
      flexDirection: 'column',
    },
    headingContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    heading: {
      fontSize: theme.typography.pxToRem(30),
      fontWeight: theme.typography.fontWeightRegular as any,
      borderLeft: '3px solid gray',
      paddingLeft: theme.spacing(1),
      minWidth: '10rem',
    },
    expandIcon: {
      width: '2.5rem',
      height: '2.5rem',
    },
    rightDrawer: {
      width: window.innerWidth > 1200 ? '30%' : '40%', // Drawerの幅を調整
    },
    bottomDrawer: {
      height: '100%', // spのDrawerの高さを調整
    },
    selected: {},
    sortNav: {
      height: '3rem',
      color: '#000000',
      '&$selected': {
        color: '#02A4FF',
      },
    },
    withBorderRight: {
      borderRight: '2px solid #000000',
    },
    bottomNavigation: {
      paddingTop: '1rem',
      justifyContent: 'flex-end',
    },
    label: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
    },
  })
);

const defaultProps = {
  loading: false,
};

type Props = {
  children?: ReactNode;
  tasks: TaskModel[];
  currentUser: UserModel | null;
  onSubmitTask: (params: AddFormData) => void;
  onUpdateTask: (params: FormData) => void;
  onSortTask: (taskId: number, moveTo: number) => Promise<void>;
  onDeleteTask: (taskId: number) => void;
  onFetchTaskHistory: (taskId: number) => void;
  onFetchTaskDetail: (taskId: number) => void;
  handleRequestEdit: (lockabelType: string, lockableId: number) => void;
  handleUnlock: (lockedRecordId: number) => void;
  lockedRecord: LockedRecordModel;
} & typeof defaultProps;

type SortType = 'original' | 'priority' | 'dueDate';

export default function Tasks(props: Props) {
  const {
    tasks,
    currentUser,
    onSubmitTask,
    onUpdateTask,
    onSortTask,
    onDeleteTask,
    onFetchTaskHistory,
    onFetchTaskDetail,
    handleRequestEdit,
    handleUnlock,
    lockedRecord,
  } = props;

  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [openAddTaskForm, setOpenAddTaskForm] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sortType, setSortType] = useState<SortType>('original');
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [openNonEditableAlert, setOpenNonEditableAlert] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const selectedTask = useSelector((state: RootState) => state.task.task);
  const [isClickHandled, setIsClickHandled] = useState(false); // ダブルクリック対応

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleSortType = (event: React.ChangeEvent<{}>, value: SortType) => {
    setSortType(value);
  };

  const navigationActions = [
    { value: 'original', label: 'ソートなし', borderRight: true },
    { value: 'dueDate', label: '締切日順', borderRight: true },
    { value: 'priority', label: '優先度順', borderRight: false },
  ];

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const incompletedTasks = tasks.filter(
    (task) => !task.trashed && task.progress_status === 'incompleted'
  );

  const completedTasks = tasks.filter(
    (task) => !task.trashed && task.progress_status === 'completed'
  );

  const trashedTasks = tasks.filter((task) => task.trashed);

  const handleOpenAddTaskForm = () => {
    setOpenAddTaskForm(true);
  };

  const handleSubmit = async (formData: AddFormData) => {
    await onSubmitTask(formData);
    setOpenAddTaskForm(false);
  };

  const handleCloseForm = () => {
    setOpenAddTaskForm(false);
  };

  const conparePriority = (a: TaskModel, b: TaskModel) => {
    return convertPriorityToInt(a.priority) - convertPriorityToInt(b.priority);
  };

  const convertPriorityToInt = (priority: string) => {
    switch (priority) {
      case 'high':
        return 1;
      case 'middle':
        return 2;
      case 'low':
        return 3;
      case 'unset':
        return 4;
      default:
        return 5;
    }
  };

  const conpareDueDate = (a: TaskModel, b: TaskModel) => {
    // 未入力のデータは後に表示
    if (a.due_date === null) return 1;
    if (b.due_date === null) return -1;
    return new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
  };

  const sortTasksByCondition = (tasks: TaskModel[]): TaskModel[] => {
    if (sortType === 'dueDate') {
      return [...tasks].sort((a, b) => conpareDueDate(a, b));
    } else {
      // (sortType === "priority")
      return [...tasks].sort((a, b) => conparePriority(a, b));
    }
  };

  const conpareCreatedAt = (a: TaskModel, b: TaskModel) => {
    // 未入力のデータは後に表示
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  };

  const sortTasksByCreatedAt = (tasks: TaskModel[]): TaskModel[] => {
    return [...tasks].sort((a, b) => conpareCreatedAt(a, b));
  };

  const SortedProgressStatusList = [
    {
      id: 'incompleted',
      name: '未完了',
      taskList:
        sortType === 'original'
          ? incompletedTasks
          : sortTasksByCondition(incompletedTasks),
    },
    {
      id: 'completed',
      name: '完了',
      taskList:
        sortType === 'original'
          ? sortTasksByCreatedAt(completedTasks)
          : sortTasksByCondition(completedTasks),
    },
    {
      id: 'trashed',
      name: 'ゴミ箱',
      taskList:
        sortType === 'original'
          ? sortTasksByCreatedAt(trashedTasks)
          : sortTasksByCondition(trashedTasks),
    },
  ];

  const getDrawerAnchor = () => {
    return window.innerWidth > 768 ? 'right' : 'top';
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      clearSelectedTask();
      setDrawerOpen(open);
    };

  const handleTaskCardClick = async (taskId: number) => {
    if (isClickHandled) return;
    setIsClickHandled(true);
    await setSelectedTaskId(taskId);
    handleRequestEdit('Task', taskId);
    setWaiting(true);
  };

  const handleTaskUpdate = async (params: FormData) => {
    await clearSelectedTask();
    onUpdateTask(params);
    setDrawerOpen(false);
  };

  const handleTaskDelete = async (taskId: number) => {
    await clearSelectedTask();
    onDeleteTask(taskId);
    setDrawerOpen(false);
  };

  useEffect(() => {
    const taskId = getIdFromFragment();

    // taskIdと一致するタスクidが存在する場合、selectedTaskに一致したタスクを設定
    // 存在しない場合、selectedTaskを外し強調を無効
    if (taskId) {
      const task = tasks.find((task) => task.id === parseInt(taskId, 10));
      if (task) {
        setSelectedTaskId(task.id);
      } else {
        setSelectedTaskId(0);
      }
    } else {
      setSelectedTaskId(0);
    }
  }, [tasks]);

  const getIdFromFragment = () => {
    const fragment = location.hash;

    // フラグメントが指定された形式に従っているかチェック
    if (fragment.startsWith('#task_id-')) {
      // ID部分を抽出
      const id = fragment.substring('#task_id-'.length);
      return id;
    }

    // 適切な形式のフラグメントがない場合はnullを返す
    return null;
  };

  useEffect(() => {
    // 1秒後にフラグメントを削除する
    const timer = setTimeout(() => {
      navigate(location.pathname);
    }, 1000);

    // コンポーネントがアンマウントされるときにタイマーをクリア
    return () => clearTimeout(timer);
  }, [navigate, location.pathname]);

  // Drawerを閉じる(setDrawerOpen(false))箇所には、clearSelectedTaskを記述する
  const clearSelectedTask = async () => {
    setIsClickHandled(false);
    await handleUnlock(lockedRecord.id);
    setSelectedTaskId(0);
  };

  // 編集不可アラート
  const renderNonEditableAlert = () => {
    return (
      <AlertDialog
        onAgree={() => {
          handleCloseNonEditableAlert();
        }}
        onClose={() => {
          setIsClickHandled(false);
          setOpenNonEditableAlert(false);
        }}
        open={openNonEditableAlert}
        title={'編集不可'}
        description={
          '他の方が編集中のため入力できません。\nタスクが更新されている可能性がありますので、\nしばらくしてから、再読み込みした後にお試しください。'
        }
        singleButton
      />
    );
  };

  // ロックレコードが作成されたらTaskDetailを取得
  useEffect(() => {
    if (waiting && selectedTaskId === lockedRecord?.lockableId) {
      onFetchTaskDetail(selectedTaskId);
    }
    if (waiting && !lockedRecord?.id) {
      setOpenNonEditableAlert(true);
    }
  }, [lockedRecord]);

  // selectedTaskが取得できたらDrawerをopenする
  // waiting: タスクを選択後掲示板からの遷移でDrawerが開かないよう制御するため
  useEffect(() => {
    if (waiting && selectedTask) {
      setDrawerOpen(true);
    }
  }, [selectedTask]);

  const handleCloseNonEditableAlert = () => {
    setOpenNonEditableAlert(false);
    setSelectedTaskId(0);
  };

  return (
    <>
      <AlertDialog
        title={'メッセージを適宜変える'}
        description={'詳細メッセージを適宜変える'}
        open={openAlert}
        onAgree={() => handleCloseAlert()}
        onClose={() => handleCloseAlert()}
        singleButton
      />
      {renderNonEditableAlert()}
      <PageTitle>やることリスト</PageTitle>
      <div id="pageTop" className="container -with-bar">
        <div className={`${classes.root} Task`}>
          {!openAddTaskForm ? (
            <div className="Task__add" onClick={() => handleOpenAddTaskForm()}>
              <img
                className="Task__add--img"
                src="/assets/img/tasks/icon/add_blue.svg"
                alt="タスク新規作成"
              />
              <span>新規作成</span>
            </div>
          ) : (
            <AddTaskForm
              currentUser={currentUser}
              onSubmit={handleSubmit}
              handleCloseForm={handleCloseForm}
            />
          )}
          <BottomNavigation
            value={sortType}
            onChange={handleSortType}
            showLabels={true}
            className={`${classes.root} ${classes.bottomNavigation}`}
          >
            {navigationActions.map((action) => (
              <BottomNavigationAction
                key={action.value}
                value={action.value}
                className={`${classes.sortNav} ${
                  action.borderRight ? classes.withBorderRight : ''
                } ${sortType === action.value ? classes.selected : ''}`}
                label={<span className={classes.label}>{action.label}</span>}
              />
            ))}
          </BottomNavigation>
          {SortedProgressStatusList.map((status) => (
            <Accordion
              key={status.id}
              defaultExpanded
              classes={{ root: classes.accordionRoot }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls={`${status.id}-content`}
                id={`${status.id}-header`}
              >
                <div className={classes.headingContainer}>
                  <Typography className={classes.heading}>
                    {status.name}
                    {`（${status.taskList.length}）`}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {sortType === 'original' ? (
                  <OriginalSortedTasks
                    status={status}
                    incompletedTasks={incompletedTasks}
                    handleTaskCardClick={handleTaskCardClick}
                    onSortTask={onSortTask}
                    selectedTaskId={selectedTaskId}
                  />
                ) : (
                  <ConditionalSortedTasks
                    status={status}
                    handleTaskCardClick={handleTaskCardClick}
                    selectedTaskId={selectedTaskId}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      {/* Drawerのコンポーネント */}
      <Drawer
        anchor={getDrawerAnchor()}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        classes={{
          paper:
            getDrawerAnchor() === 'right'
              ? classes.rightDrawer
              : classes.bottomDrawer,
        }}
        ModalProps={{
          BackdropProps: {
            style: {
              backgroundColor: 'transparent', // オーバーレイの背景色を透明に設定
            },
          },
        }}
      >
        <TaskDrawer
          setDrawerOpen={setDrawerOpen}
          handleTaskUpdate={handleTaskUpdate}
          handleTaskDelete={handleTaskDelete}
          onFetchTaskHistory={onFetchTaskHistory}
          selectedTask={selectedTask}
          clearSelectedTask={clearSelectedTask}
        />
      </Drawer>
    </>
  );
}

Tasks.defaultProps = defaultProps;
