import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Auth from '../features/auth/Auth';
// ページを 作成したら import すること。
import Home from '../features/appointment/Home';
import Files from '../features/dataFile/Files';
import IdSheet from '../features/idSheet/IdSheet';
import Messages from '../features/message/Message';
import ScheduleArticles from '../features/agenda/ScheduleArticles';
import Schedule from '../features/appointment/Schedules';
import Furniture from '../features/furniture/furniture';
import ArticleDetail from '../features/article/ArticleDetail';
import Articles from '../features/designStep/Articles';
import AppointmentDetail from '../features/appointment/AppointmentDetail';
import NotificationSetting from '../features/notificationSetting/notificationSetting';
import Tasks from '../features/task/Tasks';
import Materials from '../features/material/Materials';
import Error from '../components/pages/Error';
import Tutorial from 'components/pages/Tutorials';
import Keep from '../components/pages/Keeps/index';
import ThreeDViewer from 'components/pages/ThreeDViewer';

export default function AppRouter() {
  const location = useLocation();

  useEffect(() => {
    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

    if (measurementId) {
      window.gtag('config', measurementId, { page_path: location.pathname });
    }
  }, [location]);
  return (
    <Auth>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/files" element={<Files />} />
        <Route path="/idsheet" element={<IdSheet />} />
        <Route path="/agendas/:id" element={<ScheduleArticles />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/schedules" element={<Schedule />} />
        <Route path="/furniture" element={<Furniture />} />
        <Route path="/articles/:id" element={<ArticleDetail />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/appointments/:id" element={<AppointmentDetail />} />
        <Route path="/tutorials" element={<Tutorial />} />
        <Route path="/keeps" element={<Keep />} />
        <Route path="/three_d_viewer" element={<ThreeDViewer />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/materials" element={<Materials />} />
        <Route
          path="/notification_settings"
          element={<NotificationSetting />}
        />
        <Route path="/error" element={<Error />} />
        {/* 404 not found */}
        <Route path="/*" element={<Navigate to="/error" />} />
      </Routes>
    </Auth>
  );
}
