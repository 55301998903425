import { MileStoneCompletion } from 'api/model/user';
import React from 'react';
import ModalBase from '../../atoms/ModalBase';

const defaultProps = {};

export type ModalProps = {
  milestones: MileStoneCompletion[];
  open: boolean;
  onClose: () => void;
};

export default function HomeFlowModal(props: ModalProps) {
  const { onClose, open, milestones } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <ModalBase onClose={handleClose} open={open} className="Modal">
      <div className="Modal -flow-modal">
        <div className="Modal__content">
          <div className="Modal__nav">
            <p className="Modal__title">工程確認</p>
            <span className="Modal__nav-right" onClick={() => handleClose()} />
          </div>
          <div className="Modal__main">
            <div className="Modal__inner">
              <ul className="Modal__flow-list">
                {milestones &&
                  milestones.map((milestone, index) => (
                    <li
                      className={`${milestone.completed && '-checked'}`}
                      key={index}
                    >
                      {milestone.label}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  );
}

HomeFlowModal.defaultProps = defaultProps;
