import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { loginUser } from 'features/user/userSlice';
import { fetchIdSheets } from '../idSheetSlice';
import * as Sentry from '@sentry/browser';
import IdSheet from '../../../components/pages/IdSheet';

type Props = {
  children?: ReactNode;
};

export default function IdSheetWithRedux(props: Props) {
  // TODO: as any良くない
  const idSheets = useSelector(
    (state: RootState) => state.idSheet.idSheets
  ) as any;

  const dispatch = useDispatch<any>();

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchIdSheets());
        dispatch(loginUser());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  return (
    <>
      <IdSheet idSheets={idSheets} />
    </>
  );
}
