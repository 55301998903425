import React, { ReactNode, useEffect, useState } from 'react';
import { TaskModel } from 'api/model/task';
import { FormData } from 'components/molecules/forms/TaskForm';
import TaskCard from 'components/molecules/TaskCard';
import {
  SortEnd,
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';

type Props = {
  status: {
    id: string;
    name: string;
    taskList: TaskModel[];
  };
  incompletedTasks: TaskModel[];
  handleTaskCardClick: (taskId: number) => void;
  onSortTask: (taskId: number, moveTo: number) => Promise<void>;
  selectedTaskId?: number;
};

export default function OriginalSortedTasks(props: Props) {
  const {
    status,
    incompletedTasks,
    handleTaskCardClick,
    onSortTask,
    selectedTaskId,
  } = props;

  const [isSorting, setIsSorting] = useState(false);
  const [sortedIncompletedTasks, setSortedIncompletedTasks] = useState<
    TaskModel[]
  >([]);

  // ↓初期読み込み時にデータをsetするため必要
  useEffect(() => {
    setSortedIncompletedTasks(incompletedTasks);
  }, [incompletedTasks]);

  const handleSortTaskEnd = async ({
    oldIndex,
    newIndex,
    collection,
  }: SortEnd) => {
    if (oldIndex === newIndex) return;
    setIsSorting(true);
    const taskId = sortedIncompletedTasks[oldIndex].id;
    const moveTo = sortedIncompletedTasks[newIndex].sort_key;

    // 差し込むタスク：
    const sortingTask = sortedIncompletedTasks[oldIndex];
    // stateの不変性を維持するため、同じ内容のデータを複製
    const UpdatingSortedIncompletedTasksData = [...sortedIncompletedTasks];
    UpdatingSortedIncompletedTasksData.splice(oldIndex, 1);
    UpdatingSortedIncompletedTasksData.splice(newIndex, 0, sortingTask);
    setSortedIncompletedTasks(UpdatingSortedIncompletedTasksData);
    await onSortTask(taskId, moveTo);
    setIsSorting(false);
  };

  const SortableItem: React.ComponentClass<
    SortableElementProps & { selectedTaskId: any } & {
      task: any;
    } & { handleTaskCardClick: any }
  > = SortableElement(({ task, handleTaskCardClick, selectedTaskId }: any) => (
    <div key={task.id}>
      <TaskCard
        onClick={() => handleTaskCardClick(task.id)}
        selectedTaskId={selectedTaskId}
        isSortable={true}
        task={task}
      />
    </div>
  ));
  const SortableList: React.ComponentClass<
    SortableContainerProps & { children: ReactNode },
    any
  > = SortableContainer(
    (
      { children }: { children: ReactNode },
      { className }: { className: string }
    ) => {
      return <ul className={className}>{children}</ul>;
    }
  );

  return (
    <>
      {status.id === 'incompleted' ? (
        <SortableList
          onSortEnd={handleSortTaskEnd}
          axis="y"
          distance={0}
          useDragHandle={true}
        >
          {sortedIncompletedTasks.map((task, index) => (
            <SortableItem
              key={`${task.id}-${index}`}
              index={index}
              collection={'task'}
              disabled={isSorting}
              task={task}
              selectedTaskId={selectedTaskId}
              handleTaskCardClick={handleTaskCardClick}
            />
          ))}
        </SortableList>
      ) : (
        status.taskList.map((task) => (
          <div key={task.id}>
            <TaskCard
              task={task}
              onClick={() => handleTaskCardClick(task.id)}
              selectedTaskId={selectedTaskId}
              isSortable={false}
            />
          </div>
        ))
      )}
    </>
  );
}
