import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { useParams } from 'react-router-dom';
import { fetchMaterial } from '../materialSlice';
import * as Sentry from '@sentry/browser';
import Materials from '../../../components/pages/Materials';
import { MaterialModel } from '../../../api/model/material';

type Props = {
  children?: ReactNode;
};

export default function MaterialsWithRedux(props: Props) {
  const dispatch = useDispatch<any>();
  const { id } = useParams();

  // TODO: as any良くない
  const material = useSelector(
    (state: RootState) => state.material.material
  ) as MaterialModel;
  const fetching = useSelector((state: RootState) => state.material.fetching);
  const agenda = useSelector((state: RootState) => state.material.material);

  useEffect(() => {
    if (agenda) {
      localStorage.setItem('agenda', JSON.stringify(agenda));
    }
  }, [agenda]);

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchMaterial());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <Materials material={material} fetching={fetching} />
    </>
  );
}
