// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// FIXME: rest のエラーが解消できないのでやむなく。
import React, { ReactNode, HTMLProps } from 'react';
import { Link, LinkProps, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const defaultProps = {
  children: '',
};

type Props =
  | ({
      to?: string;
      children?: ReactNode;
      back?: boolean;
    } & HTMLProps<HTMLAnchorElement>)
  | LinkProps;

const StyledLink = styled(Link)`
  color: inherit;
  transition: opacity 0.5s;
  text-decoration: none;
  cursor: pointer;
`;

const StyledA = styled.a`
  color: inherit;
  transition: opacity 0.5s;
  text-decoration: none;
  cursor: pointer;
`;

export const A = (props: Props) => {
  const { href, children, to, back, staticContext, ...rest } = props;
  const navigate = useNavigate();

  return (
    <>
      {props.to && (
        <StyledLink to={to} {...rest}>
          {children}
        </StyledLink>
      )}
      {props.href && (
        <StyledA href={href} {...rest}>
          {children}
        </StyledA>
      )}
      {props.back && (
        <span
          onClick={() => {
            navigate(-1);
          }}
        >
          {children}
        </span>
      )}
    </>
  );
};

A.defaultProps = defaultProps;

export default A;
