import React from 'react';

const defaultProps = {
  colorCode: '',
};

type Props = {
  colorCode: string;
} & typeof defaultProps;

export const HouseholdFlag = (props: Props) => {
  const { colorCode } = props;
  return (
    <svg
      className="check-flag"
      xmlns="http://www.w3.org/2000/svg"
      width="19.805"
      height="28.5"
      viewBox="0 0 19.805 28.5"
    >
      <path
        fill={colorCode}
        d="M538.337 1921.882l-15.952-8.772c-.018-.01-.037-.017-.056-.026l-.037-.017a.881.881 0 0 0-.143-.045l-.036-.006a.865.865 0 0 0-.142-.015h-.023a.87.87 0 0 0-.157.016l-.039.008a.927.927 0 0 0-.135.042l-.016.006a.944.944 0 0 0-.106.054l-.026.018-.036.025a.9.9 0 0 0-.1.085l-.015.013a.887.887 0 0 0-.1.117l-.022.034a.879.879 0 0 0-.064.119c0 .007-.007.014-.01.021a.891.891 0 0 0-.045.147c0 .013-.006.026-.008.04a.913.913 0 0 0-.016.161v25.779H519.9a.907.907 0 0 0 0 1.814h4.112a.907.907 0 0 0 0-1.814h-1.157v-7.7l15.483-8.514a.911.911 0 0 0 0-1.593z"
        transform="translate(-519 -1913)"
      />
    </svg>
  );
};

HouseholdFlag.defaultProps = defaultProps;
export default HouseholdFlag;
