import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetch,
  create,
  update,
  FileParams,
  destroy,
  search,
  scrollFetch,
  messageDestroy,
} from '../../api/message';
import { fetch as fetchMessage } from '../../api/systemMessage';
import { AppThunk } from '../../App/store';
import { MessageModel } from '../../api/model/message';
import { PinnedMessageModel } from '../../api/model/pinnedMessage';
import { serializeError } from 'serialize-error';
import { SystemMessageModel } from '../../api/model/systemMessage';

type MessageLoaded = {
  messages: MessageModel[];
  messageCount?: number;
};

type SearchedMessageLoaded = {
  messages: MessageModel[];
  messageCount?: number;
  selectedTab: string;
  searchedQuery: string;
};

type SystemMessageLoaded = {
  systemMessages: SystemMessageModel[];
};

type CreateMessageLoaded = {
  message: MessageModel;
};

type fetchNewMessageLoaded = {
  messages: MessageModel[];
};

type MessageState = {
  messages: MessageModel[];
  systemMessages: SystemMessageModel[];
  page: number;
  getMessagesNum: number;
  loading: boolean;
  fetching: boolean;
  selectedTab: string;
  searchedQuery: string;
  messageCount: number;
  error: any;
};

const initialState: MessageState = {
  messages: [],
  systemMessages: [],
  page: 2,
  getMessagesNum: 1,
  loading: false,
  fetching: true,
  selectedTab: 'all',
  searchedQuery: '',
  messageCount: 0,
  error: null,
};

const addMessage = (messages: MessageModel[], newMessage: MessageModel) => {
  return Array.from(new Set([newMessage, ...messages]));
};

const updatedMessage = (messages: MessageModel[], newMessage: MessageModel) => {
  const filteredMessages = messages.filter(
    (message) => message.message.id !== newMessage.message.id
  );
  return [newMessage, ...filteredMessages];
};

const addFetchMessages = (
  messages: MessageModel[],
  newMessages: MessageModel[]
) => {
  const messagesArray = messages
    .concat(newMessages)
    .sort((previousMessage, followingMessage) => {
      if (previousMessage.message.sent_at < followingMessage.message.sent_at) {
        return 1;
      } else {
        return -1;
      }
    });
  const filteredMessagesArray = messagesArray.filter(
    (message, index, self) =>
      self.findIndex(
        (messageForDuplicationConforming) =>
          messageForDuplicationConforming.message.id === message.message.id
      ) === index
  );
  return filteredMessagesArray;
};

const message = createSlice({
  name: 'message',
  initialState,
  reducers: {
    fetchMessagesStart(state) {
      state.loading = true;
      state.fetching = true;
    },
    fetchMessagesSuccess(state, action: PayloadAction<MessageLoaded>) {
      const { messages, messageCount } = action.payload;
      state.messages = messages;
      state.messageCount = messageCount || 0;
      state.page = initialState.page;
      state.getMessagesNum = initialState.getMessagesNum;
      state.loading = false;
      state.fetching = false;
      state.error = null;
    },
    fetchMessagesFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
      state.fetching = false;
    },
    fetchSystemMessagesStart(state) {
      state.loading = true;
    },
    fetchSystemMessagesSuccess(
      state,
      action: PayloadAction<SystemMessageLoaded>
    ) {
      const { systemMessages } = action.payload;
      state.systemMessages = systemMessages;
      state.loading = false;
      state.error = null;
    },
    fetchSystemMessagesFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    createMessageStart(state) {
      state.loading = true;
    },
    createMessageSuccess(state, action: PayloadAction<CreateMessageLoaded>) {
      const { message } = action.payload;
      state.messages = addMessage(state.messages, message);
      state.loading = false;
      state.error = null;
    },
    createMessageFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    updateMessageStart(state) {
      state.loading = true;
    },
    updateMessageSuccess(state, action: PayloadAction<CreateMessageLoaded>) {
      const { message } = action.payload;
      state.messages = updatedMessage(state.messages, message);
      state.loading = false;
      state.error = null;
    },
    updateMessageFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    setPinnedMessage(state, action: PayloadAction<PinnedMessageModel>) {
      const { id, message_id } = action.payload;
      const message = state.messages.find((m) => m.message.id === message_id);
      if (message) message.message.pinned_message_id = id;
    },
    deletePinnedMessageId(state, action: PayloadAction<number>) {
      const messageId = action.payload;
      const message = state.messages.find((m) => m.message.id === messageId);
      if (message) message.message.pinned_message_id = null;
    },
    deleteFileStart(state) {
      state.loading = true;
    },
    deleteFileSuccess(state, action: PayloadAction<MessageLoaded>) {
      state.loading = false;
      state.error = null;
    },
    deleteFileFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    fetchNewMessagesStart(state) {
      state.loading = true;
    },
    fetchNewMessagesSuccess(
      state,
      action: PayloadAction<fetchNewMessageLoaded>
    ) {
      const { messages } = action.payload;
      state.getMessagesNum = messages.length;
      if (messages.length > 0) {
        const currentMessageIds = state.messages.map(
          (message) => message.message.id
        );
        if (
          // NOTE: ブラウザによって意図せずに連続で叩いてしまうので
          // 新しく取得した投稿のidsが既存の投稿のidsと重複していない時のみstate.messagesを更新
          messages.every(
            (message) => !currentMessageIds.includes(message.message.id)
          )
        ) {
          state.messages = addFetchMessages(state.messages, messages);
          state.page = state.page + 1;
        }
      }
      state.loading = false;
      state.error = null;
    },
    fetchNewMessagesFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    searchMessagesStart(state) {
      state.loading = true;
      state.fetching = true;
    },
    searchMessagesSuccess(state, action: PayloadAction<SearchedMessageLoaded>) {
      const { messages, messageCount, selectedTab, searchedQuery } =
        action.payload;
      state.messages = messages;
      state.messageCount = messageCount || 0;
      state.page = initialState.page;
      state.getMessagesNum = initialState.getMessagesNum;
      state.loading = false;
      state.fetching = false;
      state.selectedTab = selectedTab;
      state.searchedQuery = searchedQuery;
      state.error = null;
    },
    searchMessagesFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
      state.fetching = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchMessagesSuccess,
  fetchMessagesStart,
  fetchMessagesFailure,
  fetchSystemMessagesSuccess,
  fetchSystemMessagesStart,
  fetchSystemMessagesFailure,
  createMessageSuccess,
  createMessageStart,
  createMessageFailure,
  updateMessageSuccess,
  updateMessageStart,
  updateMessageFailure,
  setPinnedMessage,
  deletePinnedMessageId,
  deleteFileStart,
  deleteFileSuccess,
  deleteFileFailure,
  fetchNewMessagesStart,
  fetchNewMessagesSuccess,
  fetchNewMessagesFailure,
  searchMessagesStart,
  searchMessagesSuccess,
  searchMessagesFailure,
  resetError,
} = message.actions;
export default message.reducer;

export const fetchMessages = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchMessagesStart());
    const res = await fetch();
    dispatch(
      fetchMessagesSuccess({
        messages: res.data.data.messages,
        messageCount: res.data.data.message_count,
      })
    );
  } catch (err: any) {
    dispatch(fetchMessagesFailure(err));
    throw err;
  }
};

export const fetchSystemMessage = (): AppThunk => async (dispatch) => {
  const filterType = 'construction';

  try {
    dispatch(fetchSystemMessagesStart());
    const res = await fetchMessage({ filterType: filterType });
    dispatch(
      fetchSystemMessagesSuccess({
        systemMessages: res.data.data.system_messages,
      })
    );
  } catch (err: any) {
    dispatch(fetchSystemMessagesFailure(err));
    throw err;
  }
};

// やることからの引用投稿(quotable)はcreateのみ
export const createMessage =
  (
    body: string,
    files: FileParams[],
    status?: string,
    quotableId?: number,
    quotableType?: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createMessageStart());
      const res = await create({
        body: body,
        files: files,
        status: status,
        quotableId: quotableId,
        quotableType: quotableType,
      });
      dispatch(
        createMessageSuccess({
          message: res.data.data,
        })
      );
    } catch (err) {
      dispatch(createMessageFailure(err as Error));
      throw err;
    }
  };

export const updateMessage =
  (
    messageId: number,
    body: string,
    files: FileParams[],
    status?: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateMessageStart());
      const res = await update({
        messageId: messageId,
        body: body,
        files: files,
        status: status,
      });
      dispatch(
        updateMessageSuccess({
          message: res.data.data,
        })
      );
    } catch (err) {
      dispatch(updateMessageFailure(err as Error));
      throw err;
    }
  };

export const resetPinnedMessage =
  (messageId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deletePinnedMessageId(messageId));
    } catch (err) {
      throw err;
    }
  };

export const deleteFile =
  (fileId: number, messageId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteFileStart());
      const res = await destroy({ fileId, messageId });
      dispatch(
        deleteFileSuccess({
          messages: res.data.data.messages,
        })
      );
    } catch (err: any) {
      dispatch(deleteFileFailure(err));
      throw err;
    }
  };

export const deleteMessage =
  (messageId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteFileStart());
      const res = await messageDestroy({ messageId });
      dispatch(
        deleteFileSuccess({
          messages: res.data.data.messages,
        })
      );
    } catch (err: any) {
      dispatch(deleteFileFailure(err));
      throw err;
    }
  };

export const fetchNewMessages =
  (page: number, filterType: string, searchKeywords?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchNewMessagesStart());
      const res = await scrollFetch({ page, filterType, searchKeywords });
      dispatch(
        fetchNewMessagesSuccess({
          messages: res.data.data.messages,
        })
      );
    } catch (err: any) {
      dispatch(fetchNewMessagesFailure(err));
      throw err;
    }
  };

export const searchMessages =
  (page: number, filterType: string, searchKeywords?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(searchMessagesStart());
      const res = await search({ page, filterType, searchKeywords });
      dispatch(
        searchMessagesSuccess({
          messages: res.data.data.messages,
          messageCount: res.data.data.message_count,
          selectedTab: filterType,
          searchedQuery: searchKeywords || initialState.searchedQuery,
        })
      );
    } catch (err: any) {
      dispatch(searchMessagesFailure(err));
      throw err;
    }
  };
