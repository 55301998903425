import { apiClient } from './client';
import { idSheetModel } from './model/idSheet';

export type FetchRes = {
  data: {
    id_sheets: idSheetModel[];
  };
};

export async function fetch() {
  return apiClient.get<FetchRes>(`/id_sheets`);
}
