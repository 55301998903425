import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';

export type Props = {
  id: number;
  title: string;
  contents: 'task' | 'material';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: () => ({
      width: '80%',
      marginBottom: 10,
      marginTop: 20,
      border: '1.5px solid #d6d6d6',
      borderRadius: 9,
      filter: 'none',
      cursor: 'pointer',
    }),
    content: {
      padding: '16px',
      '&.MuiCardContent-root:last-child': {
        paddingBottom: '16px',
      },
    },
    title: {
      fontSize: 14,
      fontWeight: 'bold',
    },
  })
);

export default function QuoteCard(props: Props) {
  const navigate = useNavigate();
  const { id, title, contents } = props;
  const classes = useStyles();

  const onClickCard = () => {
    if (contents === 'task') {
      navigate(`/tasks#task_id-${id}`);
    } else {
      navigate(`/articles/${id}#message`);
    }
  };

  return (
    <Card
      className={`${classes.root}`}
      variant="outlined"
      onClick={onClickCard}
    >
      <CardContent className={`${classes.content}`}>
        <Typography className={`${classes.title}`} color="textSecondary">
          {contents === 'task'
            ? `やること：「 ${title} 」 について`
            : `部資材仕様特性：「 ${title} 」 についての質問`}
        </Typography>
      </CardContent>
    </Card>
  );
}
