import { SystemMessageModel } from '../../../api/model/systemMessage';
import DOMPurify from 'dompurify';
import React from 'react';
import './SystemMessage.scss';

const defaultProps = {};

type Props = {
  systemMessages: SystemMessageModel[];
};

export const SystemMessage = (props: Props) => {
  const convertHyperLinkText = (text: string) => {
    const tags: RegExp = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    const link: RegExp = /(https?:\/\/\S+)/g;

    // XSS対策
    const textWithoutTags = text.replace(tags, '');
    return textWithoutTags.replace(link, '<a href="$1" target="_blank">$1</a>');
  };

  return (
    <>
      <>
        <div className="SystemMessage__item -info">
          <div className="SystemMessage__item-wrap">
            {props.systemMessages.map((systemMessage, index) => (
              <div key={index}>
                <p className="SystemMessage__item-title">
                  {systemMessage.title}
                </p>
                <p
                  className="SystemMessage__item-message"
                  dangerouslySetInnerHTML={{
                    __html: convertHyperLinkText(systemMessage.body),
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
};

SystemMessage.defaultProps = defaultProps;
export default SystemMessage;
