import React, { ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const defaultProps = {
  size: 20,
  className: '',
  disableShrink: false,
};

type Props = {
  size?: number;
  className?: any;
  style?: any;
  children?: ReactNode;
  disableShrink?: boolean;
} & typeof defaultProps;

export default function Circular(props: Props) {
  const { size, className, style, disableShrink } = props;

  return (
    <CircularProgress
      color="primary"
      size={size}
      className={className}
      style={style}
      disableShrink={disableShrink}
    />
  );
}

Circular.defaultProps = defaultProps;
