import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../App/store';
import { LockedRecordModel } from 'api/model/lockedRecord';
import { serializeError } from 'serialize-error';
import { create, destroy } from '../../api/lockedRecord';

type CreateLockedRecordLoaded = {
  lockedRecord: LockedRecordModel;
};

type DeleteLockedRecordLoaded = {
  lockedRecordId: number;
  lockedRecordStatus: boolean;
};

type LockedRecordState = {
  lockedRecord: null | LockedRecordModel;
  lockedRecordId: null | number;
  lockedRecordStatus: boolean;
  loading: boolean;
  error: any;
};

const initialState: LockedRecordState = {
  lockedRecord: null,
  lockedRecordId: null,
  lockedRecordStatus: false,
  loading: false,
  error: null,
};

const lockedRecord = createSlice({
  name: 'lockedRecord',
  initialState,
  reducers: {
    createLockedRecordStart(state) {
      state.loading = true;
    },
    createLockedRecordSuccess(
      state,
      action: PayloadAction<CreateLockedRecordLoaded>
    ) {
      const { lockedRecord } = action.payload;
      state.lockedRecord = lockedRecord;
      state.error = null;
      state.loading = false;
    },
    createLockedRecordFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    deleteLockedRecordStart(state) {
      state.loading = true;
    },
    deleteLockedRecordSuccess(
      state,
      action: PayloadAction<DeleteLockedRecordLoaded>
    ) {
      const { lockedRecordId, lockedRecordStatus } = action.payload;
      state.lockedRecordId = lockedRecordId;
      state.lockedRecordStatus = lockedRecordStatus;
      state.loading = false;
      state.error = null;
    },
    deleteLockedRecordFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  createLockedRecordSuccess,
  createLockedRecordStart,
  createLockedRecordFailure,
  deleteLockedRecordStart,
  deleteLockedRecordSuccess,
  deleteLockedRecordFailure,
  resetError,
} = lockedRecord.actions;
export default lockedRecord.reducer;

export const createLockedRecord =
  (lockableType: string, lockableId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createLockedRecordStart());
      const res = await create({
        lockableType: lockableType,
        lockableId: lockableId,
      });
      await dispatch(
        createLockedRecordSuccess({
          lockedRecord: res.data.data.lockedRecord,
        })
      );
    } catch (err: any) {
      dispatch(createLockedRecordFailure(err));
      throw err;
    }
  };

export const deleteLockedRecord =
  (lockedRecordId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteLockedRecordStart());
      const res = await destroy({
        lockedRecordId: lockedRecordId,
      });
      dispatch(
        deleteLockedRecordSuccess({
          lockedRecordId: res.data.data.lockedRecordId,
          lockedRecordStatus: res.data.data.status,
        })
      );
    } catch (err: any) {
      dispatch(deleteLockedRecordFailure(err));
      throw err;
    }
  };
