import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../App/store';
import { PinnedMessageModel } from 'api/model/pinnedMessage';
import { serializeError } from 'serialize-error';
import { create, destroy } from '../../api/pinnedMessage';

type CreatePinnedMessageLoaded = {
  pinnedMessage: PinnedMessageModel;
};

type DeletePinnedMessageLoaded = {
  pinnedMessageStatus: boolean;
};

type PinnedMessageState = {
  pinnedMessage: PinnedMessageModel | null;
  pinnedMessageStatus: boolean;
  loading: boolean;
  error: any;
};

const initialState: PinnedMessageState = {
  pinnedMessage: null,
  pinnedMessageStatus: false,
  loading: false,
  error: null,
};

const pinnedMessage = createSlice({
  name: 'pinnedMessage',
  initialState,
  reducers: {
    createPinnedMessageStart(state) {
      state.loading = true;
    },
    createPinnedMessageSuccess(
      state,
      action: PayloadAction<CreatePinnedMessageLoaded>
    ) {
      const { pinnedMessage } = action.payload;
      state.pinnedMessage = pinnedMessage;
      state.error = null;
      state.loading = false;
    },
    createPinnedMessageFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    deletePinnedMessageStart(state) {
      state.loading = true;
    },
    deletePinnedMessageSuccess(
      state,
      action: PayloadAction<DeletePinnedMessageLoaded>
    ) {
      const { pinnedMessageStatus } = action.payload;
      state.pinnedMessageStatus = pinnedMessageStatus;
      state.loading = false;
      state.error = null;
    },
    deletePinnedMessageFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  createPinnedMessageStart,
  createPinnedMessageSuccess,
  createPinnedMessageFailure,
  deletePinnedMessageStart,
  deletePinnedMessageSuccess,
  deletePinnedMessageFailure,
  resetError,
} = pinnedMessage.actions;
export default pinnedMessage.reducer;

export const createPinnedMessage =
  (messageId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createPinnedMessageStart());
      const res = await create({
        messageId: messageId,
      });
      dispatch(
        createPinnedMessageSuccess({
          pinnedMessage: res.data.data.pinned_message,
        })
      );
    } catch (err: any) {
      dispatch(createPinnedMessageFailure(err));
      throw err;
    }
  };

export const deletePinnedMessage =
  (pinnedMessageId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deletePinnedMessageStart());
      const res = await destroy({
        pinnedMessageId: pinnedMessageId,
      });
      dispatch(
        deletePinnedMessageSuccess({
          pinnedMessageStatus: res.data.data.status,
        })
      );
    } catch (err: any) {
      dispatch(deletePinnedMessageFailure(err));
      throw err;
    }
  };
