import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, toggle, updateMaterial } from '../../api/article';
import { AppThunk } from '../../App/store';
import { ArticleModel } from '../../api/model/article';
import { serializeError } from 'serialize-error';

type ArticleLoaded = {
  article: ArticleModel;
};

type ToggleConfirmationLoaded = {
  checked: boolean;
  householdId: number | null;
};

type updateMaterialCompletedLoaded = {
  completed: boolean;
};

type ArticleState = {
  article: null | ArticleModel;
  loading: boolean;
  fetching: boolean;
  materialCompletedLoading: boolean;
  error: any;
};

const initialState: ArticleState = {
  article: null,
  loading: false,
  fetching: true,
  materialCompletedLoading: false,
  error: null,
};

const article = createSlice({
  name: 'article',
  initialState,
  reducers: {
    getArticleStart(state) {
      state.loading = true;
      state.fetching = true;
    },
    getArticleSuccess(state, action: PayloadAction<ArticleLoaded>) {
      const { article } = action.payload;
      state.article = article;
      state.loading = false;
      state.fetching = false;
      state.error = null;
    },
    getArticleFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
      state.fetching = false;
    },
    toggleConfirmationStart(state) {
      state.loading = true;
    },
    toggleConfirmationSuccess(
      state,
      action: PayloadAction<ToggleConfirmationLoaded>
    ) {
      const { checked, householdId } = action.payload;
      if (state.article !== null) {
        if (householdId === null) {
          state.article.households[0].checked = checked;
        } else {
          const excludedDefaultHouseholds = state.article.households.filter(
            (household, index) => index !== 0
          );
          const updatedHousehold = excludedDefaultHouseholds.find(
            (currentHousehold) => currentHousehold.id === Number(householdId)
          );
          if (updatedHousehold) {
            updatedHousehold.checked = checked;
          }
        }
      }
      state.loading = false;
      state.error = null;
    },
    toggleConfirmationFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    updateMaterialCompletedStart(state) {
      state.loading = true;
      state.materialCompletedLoading = true;
    },
    updateMaterialCompletedSuccess(
      state,
      action: PayloadAction<updateMaterialCompletedLoaded>
    ) {
      const { completed } = action.payload;
      if (state.article !== null) {
        state.article.completed = completed;
        state.loading = false;
        state.materialCompletedLoading = false;
        state.error = null;
      }
    },
    updateMaterialCompletedFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
      state.materialCompletedLoading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  getArticleSuccess,
  getArticleStart,
  getArticleFailure,
  toggleConfirmationSuccess,
  toggleConfirmationStart,
  toggleConfirmationFailure,
  updateMaterialCompletedStart,
  updateMaterialCompletedSuccess,
  updateMaterialCompletedFailure,
  resetError,
} = article.actions;
export default article.reducer;

export const getArticle =
  (articleId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getArticleStart());
      const res = await get({ articleId: articleId });
      setTimeout(
        () =>
          dispatch(
            getArticleSuccess({
              article: res.data.data.article,
            })
          ),
        1000
      );
    } catch (err: any) {
      dispatch(getArticleFailure(err));
      throw err;
    }
  };

export const toggleConfirmation =
  (articleId: number, householdId: number | null): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleConfirmationStart());
      const res = await toggle({
        articleId: articleId,
        householdId: householdId,
      });
      dispatch(
        toggleConfirmationSuccess({
          checked: res.data.data.checked,
          householdId: res.data.data.household_id,
        })
      );
    } catch (err: any) {
      dispatch(toggleConfirmationFailure(err));
      throw err;
    }
  };

export const updateMaterialCompleted =
  (articleId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateMaterialCompletedStart());
      const res = await updateMaterial({
        articleId: articleId,
      });
      dispatch(
        updateMaterialCompletedSuccess({
          completed: res.data.data.completed,
        })
      );
    } catch (err: any) {
      dispatch(updateMaterialCompletedFailure(err));
      throw err;
    }
  };
