import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import './Loading.scss';

const useStyles = makeStyles({
  loaderWrap: {
    zIndex: 1001,
  },
});

const defaultProps = {
  loading: false,
};

type Props = {
  children?: ReactNode;
} & typeof defaultProps;

export default function Loading(props: Props) {
  const classes = useStyles();
  const { loading } = props;
  return <>{loading && <LinearProgress className={classes.loaderWrap} />}</>;
}

Loading.defaultProps = defaultProps;
