import React from 'react';
import RcCalendar from 'react-calendar';
import './Calender.scss';

interface CalenderPropsOnChange {
  (date: Date | Date[]): void;
}

interface markingDate {
  date: Date;
  className?: string;
  tileContentString?: string;
}

interface CalenderProps {
  onChangeDate: CalenderPropsOnChange;
  markingDates: markingDate[];
  holydays: Date[];
  current?: Date;
}

interface getTileContentFunc {
  onChangeDate: CalenderPropsOnChange;
}

const dateCompare = (day1: Date, day2: Date) => {
  return (
    day1.getFullYear() === day2.getFullYear() &&
    day1.getMonth() === day2.getMonth() &&
    day1.getDate() === day2.getDate()
  );
};

export default function Calendar(props: CalenderProps) {
  const { onChangeDate, markingDates, current, holydays } = props;

  const RcCalendarProps = {
    showSeparateMonthLabel: true,
    value: current || new Date(),
    onChange: (date: Date | Date[]) => {
      if (date) {
        onChangeDate(date);
      }
    },
    tileContent: (args: any): any => {
      // 月表示のときのみ
      if (args.view !== 'month') {
        return null;
      }
      const date = args.date;

      let index = 0;

      while (index < markingDates.length) {
        const markingDate = markingDates[index];
        if (
          markingDate.tileContentString &&
          dateCompare(date, markingDate.date)
        ) {
          // サブで表示するコンテンツ
          return (
            <div className="Calneder__tileConents">
              <span>{markingDates[index].tileContentString}</span>
            </div>
          );
        }
        index++;
      }
      return <div className="Calneder__tileConents" />;
    },
    tileClassName: (args: any): any => {
      // 月表示のときのみ
      if (args.view !== 'month') {
        return null;
      }
      const date = args.date;

      let index = 0;
      while (index < markingDates.length) {
        const markingDate = markingDates[index];
        if (markingDate.className && dateCompare(date, markingDate.date)) {
          if (date.getDay() === 6) {
            return `${markingDate.className} saturday`;
          } else {
            return markingDate.className;
          }
        }
        index++;
      }

      // 祝日のクラス指定
      if (holydays) {
        while (index < holydays.length) {
          const markingDate = holydays[index];
          if (dateCompare(date, markingDate)) {
            return 'react-calendar__month-view__days__day--weekend';
          }
          index++;
        }
      }

      // 土曜日のクラス指定
      if (date.getDay() === 6) {
        return 'saturday';
      }

      const nowDate = new Date();
      if (dateCompare(date, nowDate)) {
        return 'today';
      }
      return '';
    },
  };

  return (
    <div className="Calneder">
      <RcCalendar {...RcCalendarProps} />
    </div>
  );
}

Calendar.defaultProps = {
  markingDates: [],
  onChangeDate: () => {},
};
