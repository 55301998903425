import React from 'react';
import ModalBase from '../../atoms/ModalBase';
import { useSelector } from 'react-redux';
import { RootState } from 'App/rootReducer';
import { TaskModel } from 'api/model/task';
import { datetimeStringWithSlashAndSeconds } from 'libs/date';
import CloseIcon from '@material-ui/icons/Close';

const defaultProps = {};

export type ModalProps = {
  open: boolean;
  onClose: (reason: string) => void;
};

export default function TaskHistoryModal(props: ModalProps) {
  const { open, onClose } = props;
  const histories = useSelector((state: RootState) => state.task.histories);

  const handleClose = (reason: string) => {
    onClose(reason);
  };

  const displaySharedStatusHistory = (shared_status: string): string => {
    switch (shared_status) {
      case 'draft':
        return '下書き';
      case 'private_use':
        return '非公開';
      case 'public_use':
        return '公開';
      default:
        return '';
    }
  };

  const displayPriorityHistory = (priority: string): string => {
    switch (priority) {
      case 'unset':
        return 'なし';
      case 'low':
        return '低';
      case 'middle':
        return '中';
      case 'high':
        return '高';
      default:
        return '';
    }
  };

  return (
    <ModalBase
      onClose={() => handleClose('backdropClick')}
      open={open}
      className="Modal"
    >
      <div className="Modal -task-history-modal">
        <div className="task-history-modal-header">
          <button
            className="close-button"
            onClick={() => handleClose('backdropClick')}
          >
            <CloseIcon />
          </button>
          <p className="title">アクティビティログ</p>
        </div>

        <div className="task-history-modal-histories">
          <div className="history">
            <p>公開後以降の更新履歴が閲覧できます。</p>
            <p>最新のデータは含まれません。</p>
          </div>
          {histories.map((history: TaskModel, index) => (
            <div key={index} className="history">
              <p>
                編集日：
                {datetimeStringWithSlashAndSeconds(history.created_at)}
              </p>
              <p>タイトル：{history.title}</p>
              <p>編集者：{history.editable_name}</p>
              <p>
                共有状態：
                {displaySharedStatusHistory(history.shared_status)}
              </p>
              <p>
                優先度：
                {displayPriorityHistory(history.priority)}
              </p>
              <p>
                進捗状況：
                {history.progress_status === 'completed' ? '完了' : '未完了'}
              </p>
              <p>締切：{history.due_date}</p>
              <p>編集制限：{history.can_edit ? 'あり' : 'なし'}</p>
              <p>ゴミ箱状態：{history.trashed ? 'ゴミ箱' : 'ゴミ箱でない'}</p>
              <p>詳細：{history.detail}</p>
            </div>
          ))}
        </div>
      </div>
    </ModalBase>
  );
}

TaskHistoryModal.defaultProps = defaultProps;
