import { apiClient } from './client';

export type ReviewParams = {
  rating: number;
  comment: string | null;
  constructionId: number | null;
};

export type ArticleReviewParams = {
  reviewTargetId: number;
  comment: string | null;
  constructionId: number | null;
};

export type ReviewRes = {
  data: boolean;
};

export async function create(params: ReviewParams) {
  const body = {
    rating: params.rating,
    comment: params.comment,
    construction_id: params.constructionId,
  };
  return apiClient.post<ReviewRes>('/reviews', body);
}

export async function articleReviewCreate(params: ArticleReviewParams) {
  const body = {
    review_target_id: params.reviewTargetId,
    comment: params.comment,
    construction_id: params.constructionId,
  };
  return apiClient.post<ReviewRes>('/article_reviews', body);
}
