import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetch } from '../../api/dataFile';
import { AppThunk } from '../../App/store';
import { DataFileModel } from '../../api/model/dataFile';
import { serializeError } from 'serialize-error';

type DatafileLoaded = {
  dataFiles: DataFileModel[];
};

type DatafileState = {
  dataFiles: DataFileModel[];
  loading: boolean;
  error: any;
};

const initialState: DatafileState = {
  dataFiles: [],
  loading: false,
  error: null,
};

const dataFile = createSlice({
  name: 'dataFile',
  initialState,
  reducers: {
    fetchDataFilesStart(state) {
      state.loading = true;
    },
    fetchDataFilesSuccess(state, action: PayloadAction<DatafileLoaded>) {
      const { dataFiles } = action.payload;
      state.dataFiles = dataFiles;
      state.loading = false;
      state.error = null;
    },
    fetchDataFilesFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchDataFilesSuccess,
  fetchDataFilesStart,
  fetchDataFilesFailure,
  resetError,
} = dataFile.actions;
export default dataFile.reducer;

export const fetchDataFiles =
  (filterType: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchDataFilesStart());
      const res = await fetch({ filterType });
      dispatch(
        fetchDataFilesSuccess({
          dataFiles: res.data.data.data_files,
        })
      );
    } catch (err: any) {
      dispatch(fetchDataFilesFailure(err));
      throw err;
    }
  };
