import React, { ReactNode } from 'react';
import { Skeleton as SkeletonUI } from '@material-ui/lab';
import { css } from 'styled-components';
import styled from 'styled-components';

const defaultProps = {
  type: '',
};

type Props = {
  children?: ReactNode;
  type: string;
} & typeof defaultProps;

export const media = {
  handheld: (args: any) => css`
    @media (min-width: 769px) {
      ${css(args)};
    }
  `,
  handheld320: (args: any) => css`
    @media (max-width: 768px) {
      ${css(args)};
    }
  `,
};

export const SkeletonArticleDetailBlock = styled.div`
  padding-top: 2%;
  margin-right: auto;
  margin-left: auto;
  display: block;
  ${media.handheld`
  width: 673px;
  `}
  ${media.handheld320`
  padding: 0% 6%;
  `}
`;

export const SkeletonScheduleDetailBlock = styled.div`
  padding-top: 2rem;
  margin-right: auto;
  margin-left: auto;
  display: block;
  ${media.handheld`
  width: 62.4rem;
  `}
  ${media.handheld320`
  padding: 3% 6%;
  `}
`;

// TODO: UIに合わせた形にし呼ぶ
export default function Skeleton(props: Props) {
  const { type } = props;
  return (
    <>
      {type === 'scheduleArticle' && (
        <div style={{ width: '100%', height: '45rem', display: 'block' }}>
          <SkeletonUI variant="rect" width="100%" height="33rem" />
          <SkeletonUI
            variant="text"
            width="90%"
            height="10rem"
            style={{
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
          />
        </div>
      )}
      {type === 'articleDetail' && (
        <SkeletonArticleDetailBlock>
          <SkeletonUI variant="text" width="70%" height={80} />
          <SkeletonUI variant="rect" width="50%" height={200} />
          <SkeletonUI variant="text" width="70%" height={80} />
          <SkeletonUI variant="text" width="80%" />
          <SkeletonUI variant="text" width="80%" />
          <SkeletonUI variant="text" width="80%" />
          <SkeletonUI variant="text" width="80%" />
        </SkeletonArticleDetailBlock>
      )}
      {type === 'APPO' && (
        <SkeletonScheduleDetailBlock>
          <SkeletonUI variant="rect" width="100%" height={80} />
          <SkeletonUI variant="text" width="40%" />
          <SkeletonUI variant="text" width="40%" />
          <SkeletonUI variant="text" width="100%" height={80} />
          <SkeletonUI variant="text" width="40%" height={20} />
          <SkeletonUI variant="rect" width={100} height={100} />
          <SkeletonUI variant="text" width="40%" height={20} />
          <SkeletonUI variant="rect" width={100} height={100} />
        </SkeletonScheduleDetailBlock>
      )}
    </>
  );
}

Skeleton.defaultProps = defaultProps;
