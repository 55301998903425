import PageTitle from 'components/atoms/PageTitle';
import React, { useCallback, useEffect } from 'react';

export default function Keep() {
  let currentHeight: number;

  if (window.innerWidth >= 1230) {
    currentHeight = 1400;
  } else if (window.innerWidth >= 769) {
    currentHeight = 1300;
  } else {
    currentHeight = 1300;
  }

  const adjustIframeHeight = useCallback(
    (iframe: any, panelId?: number, isOpen?: boolean) => {
      if (panelId === 0) {
        return;
      }
      // panelIdとそれに対応する高さのマッピング。基本のheightMappingと画面サイズの増加量に対するpxの増加率を定義
      let heightMapping: { [key: number]: number };
      let plusPx = 0;
      if (window.innerWidth >= 1230) {
        // 画面サイズ1230pxで調律
        heightMapping = {
          1: 1400,
          2: 1350,
          3: 900,
          4: 1300,
          5: 800,
          6: 800,
          7: 1700,
          8: 900,
          9: 900,
        };
      } else if (window.innerWidth >= 769) {
        // 画面サイズ769pxで調律 最大1229px
        plusPx = (window.innerWidth - 769) * 1.5;
        heightMapping = {
          1: 1300 + plusPx,
          2: 1100 + plusPx,
          3: 800 + plusPx,
          4: 1400 + plusPx,
          5: 700 + plusPx,
          6: 1000 + plusPx,
          7: 1800 + plusPx,
          8: 600 + plusPx,
          9: 900 + plusPx,
        };
      } else {
        // 画面サイズ370pxで調律 最大768px
        plusPx = (window.innerWidth - 370) * 1.3;
        heightMapping = {
          1: 1000 + plusPx,
          2: 1100 + plusPx,
          3: 800 + plusPx,
          4: 1000 + plusPx,
          5: 700 + plusPx,
          6: 1000 + plusPx,
          7: 1700 + plusPx,
          8: 600 + plusPx,
          9: 900 + plusPx,
        };
      }

      // panelの開閉(isOpen)に合わせて、heightMappingのpanelIdの値を加算・減算する
      if (
        typeof panelId !== 'undefined' &&
        Object.prototype.hasOwnProperty.call(heightMapping, panelId)
      ) {
        if (isOpen) {
          currentHeight = currentHeight + heightMapping[panelId];
          iframe.style.height = currentHeight + 'px';
        } else {
          currentHeight = currentHeight - heightMapping[panelId];
          iframe.style.height = currentHeight + 'px';
        }
      } else {
        iframe.style.height = currentHeight + 'px';
      }
    },
    []
  );

  useEffect(() => {
    window.addEventListener('message', function (event) {
      if (event.data.type && event.data.type === 'iframeResizedPanel') {
        const iframe = document.querySelector('.iframe-keep');
        adjustIframeHeight(iframe, event.data.panelId, event.data.isOpen);
      }
    });
  }, [adjustIframeHeight]);

  return (
    <>
      <PageTitle>データの保存方法</PageTitle>
      <div className="Keep__container">
        <iframe
          src="/tutorial/keep.html"
          className="iframe-keep"
          width="100%"
          onLoad={(e) => adjustIframeHeight(e.target)}
          title="Keep"
          scrolling="no"
        ></iframe>
      </div>
    </>
  );
}
