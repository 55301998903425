import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

export default function ThreeDViewer() {
  const fileUrl = localStorage.getItem('three_d_file_url');

  const canvasRef = useRef<HTMLCanvasElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { GLTFLoader } = require('three/examples/jsm/loaders/GLTFLoader');

  const { OrbitControls } =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('three/examples/jsm/controls/OrbitControls');

  // サイズを指定
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const initialize3D = () => {
    const canvas = canvasRef.current;

    if (canvas) {
      // レンダラーを作成
      const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
      });
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(window.devicePixelRatio);

      // シーンを作成
      const scene = new THREE.Scene();
      scene.background = new THREE.Color('#191919'); // 背景色

      // カメラを作成
      const camera: THREE.PerspectiveCamera = new THREE.PerspectiveCamera(
        75,
        sizes.width / sizes.height,
        0.1,
        1000
      );
      camera.position.set(0, 0, 3);
      camera.lookAt(0, 0, 0); // カメラを原点の方向に向ける

      // カメラコントローラーを作成
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = false; // 慣性をオフ

      // ライト
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
      scene.add(ambientLight);

      // 左からの Directional Light
      const dirLightFromLeft = new THREE.DirectionalLight(0xffffff, 2);
      dirLightFromLeft.position.set(-10, 0, 0); // 右から左方向
      scene.add(dirLightFromLeft);

      // 右からの Directional Light
      const dirLightFromRight = new THREE.DirectionalLight(0xffffff, 1);
      dirLightFromRight.position.set(10, 0, 0); // 左から右
      scene.add(dirLightFromRight);

      // 上からの Directional Light
      const dirLight = new THREE.DirectionalLight(0xffffff, 1);
      dirLight.position.set(0, 10, 0); // 上から下
      scene.add(dirLight);

      // 真下からの Directional Light
      const dirLightFromBelow = new THREE.DirectionalLight(0xffffff, 1);
      dirLightFromBelow.position.set(0, -10, 0); // 下から上
      scene.add(dirLightFromBelow);

      // 正面からの Directional Light
      const dirLightFromFront = new THREE.DirectionalLight(0xffffff, 1);
      dirLightFromFront.position.set(0, 0, 10); // 正面から
      scene.add(dirLightFromFront);

      // 真後ろからの Directional Light
      const dirLightFromBehind = new THREE.DirectionalLight(0xffffff, 1);
      dirLightFromBehind.position.set(0, 0, -10); // 真後ろから
      scene.add(dirLightFromBehind);

      const gltfLoader = new GLTFLoader();
      gltfLoader.load(fileUrl, (gltf: GLTF) => {
        const model = gltf.scene;

        // モデルのバウンディングボックスを計算
        const bbox = new THREE.Box3().setFromObject(model);
        const center = new THREE.Vector3();
        bbox.getCenter(center);
        const size = bbox.getSize(new THREE.Vector3());

        // モデルのサイズに応じてカメラの位置を調整
        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = camera.fov * (Math.PI / 180);
        let cameraZ = maxDim / 2 / Math.tan(fov / 2);

        cameraZ *= 2; // この係数を調整して、モデルとの適切な距離感を得られるようにする
        camera.position.z = center.z + cameraZ;

        // カメラをモデルの中心に向ける
        camera.lookAt(center);

        // シーンにモデルを追加する
        scene.add(model);
      });

      // レンダリングループ処理
      const tick = () => {
        controls.update();
        renderer.render(scene, camera);
        requestAnimationFrame(tick);
      };
      tick();
    }
  };

  useEffect(() => {
    initialize3D();
  }, [initialize3D]);

  useEffect(() => {
    const handleUnload = () => {
      localStorage.removeItem('three_d_file_url');
    };

    // イベントリスナーを追加
    window.addEventListener('unload', handleUnload);

    // コンポーネントアンマウント時に追加したイベントリスナーを削除
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  return (
    <div>
      <canvas
        id="canvas"
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      ></canvas>
    </div>
  );
}
