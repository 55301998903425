export async function downloadFileFromURL(url: string, filename: string) {
  const response = await fetch(url); // urlからリソースを取得
  const blob = await response.blob(); // リソースからblobを取得
  const objUrl = window.URL.createObjectURL(blob); // blobからオブジェクトURLを作成
  const link = document.createElement('a'); // 新しいアンカー要素を作成
  link.href = objUrl;
  link.download = filename; // filenameで指定した名前でファイルをダウンロード
  link.click(); // リンクをクリックしてダウンロードを実行
  // Firefoxでは、オブジェクトURLをすぐに無効にするとダウンロード前にリソースが削除されてしまうことがあるため、一定時間待ってから一時的なオブジェクトURLを解放
  // 参考：https://medium.com/@nikhidas/download-files-in-front-end-vue-a97b7ab3065
  setTimeout(() => {
    window.URL.revokeObjectURL(objUrl);
  }, 250);
}
