import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { combineReducers } from '@reduxjs/toolkit';
import user from '../features/user/userSlice';
import dataFile from '../features/dataFile/dataFileSlice';
import idSheet from '../features/idSheet/idSheetSlice';
import article from '../features/article/articleSlice';
import message from '../features/message/messageSlice';
import appointment from '../features/appointment/appointmentSlice';
import designStep from '../features/designStep/designStepSlice';
import material from '../features/material/materialSlice';
import agenda from '../features/agenda/agendaSlice';
import furniture from '../features/furniture/furnitureSlice';
import household from '../features/household/householdSlice';
import notificationSetting from '../features/notificationSetting/notificationSettingSlice';
import catalog from '../features/catalog/catalogSlice';
import pinnedMessage from '../features/pinnedMessage/pinnedMessageSlice';
import task from '../features/task/taskSlice';
import lockedRecord from '../features/lockedRecord/lockedRecordSlice';

const rootReducer = combineReducers({
  user,
  dataFile,
  idSheet,
  article,
  appointment,
  message,
  designStep,
  material,
  agenda,
  furniture,
  household,
  notificationSetting,
  catalog,
  pinnedMessage,
  task,
  lockedRecord,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
