import { apiClient } from './client';
import { UserModel } from './model/user';

/**
 * API 定義
 * USER
 *
 */

export type SearchParams = {
  keyword: string;
};

export type SearchRes = {
  data: {
    users: UserModel[];
    total: number;
  };
};

export async function search(params: SearchParams) {
  return apiClient.get<SearchRes>(
    `/employee/users?search_keyword=${params.keyword}`
  );
}

export type LoginRes = {
  data: {
    user: UserModel;
    deleted_user: boolean;
  };
};

export async function login() {
  return apiClient.post<LoginRes>(`/signins`);
}
