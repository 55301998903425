import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetch } from '../../api/catalog';
import { AppThunk } from '../../App/store';
import { CatalogModel } from '../../api/model/catalog';

type CatalogLoaded = {
  catalogs: CatalogModel[];
};

type CatalogState = {
  catalogs: CatalogModel[];
  loading: boolean;
  error: null | Error;
};

const initialState: CatalogState = {
  catalogs: [],
  loading: false,
  error: null,
};

const catalog = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    fetchCatalogsStart(state) {
      state.loading = true;
    },
    fetchCatalogsSuccess(state, action: PayloadAction<CatalogLoaded>) {
      const { catalogs } = action.payload;
      state.catalogs = catalogs;
      state.loading = false;
      state.error = null;
    },
    fetchCatalogsFailure(state, action: PayloadAction<Error>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchCatalogsSuccess,
  fetchCatalogsStart,
  fetchCatalogsFailure,
} = catalog.actions;
export default catalog.reducer;

export const fetchCatalogs = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchCatalogsStart());
    const res = await fetch();
    dispatch(
      fetchCatalogsSuccess({
        catalogs: res.data.data.catalogs,
      })
    );
  } catch (err: any) {
    dispatch(fetchCatalogsFailure(err));
    throw err;
  }
};
