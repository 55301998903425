import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { loginUser } from 'features/user/userSlice';
import { fetchAppointments } from '../appointmentSlice';
import * as Sentry from '@sentry/browser';
import Schedules from '../../../components/pages/Schedule';
import { AppointmentModel } from 'api/model/appointment';

type Props = {
  children?: ReactNode;
};
// 多世帯の場合または参加世帯が子世帯のみの場合、参加世帯を表示する
export const displayHouseholds = (
  hasUsingHouseholds: boolean,
  appointment: AppointmentModel
) => {
  return (
    hasUsingHouseholds ||
    (appointment.households && appointment.households.length > 1) ||
    appointment.households[0]?.id
  );
};

export default function SchedulesWithRedux(props: Props) {
  const dispatch = useDispatch<any>();

  // TODO: as any良くない
  const appointments = useSelector(
    (state: RootState) => state.appointment.appointments
  ) as any;
  const slideOpen = useSelector(
    (state: RootState) => state.user.slideOpen
  ) as any;
  const hasUsingHouseholds = useSelector(
    (state: RootState) => state.appointment.hasUsingHouseholds
  ) as boolean;

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchAppointments(false));
        dispatch(loginUser());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  return (
    <>
      <Schedules
        appointments={appointments}
        slideOpen={slideOpen}
        hasUsingHouseholds={hasUsingHouseholds}
      />
    </>
  );
}
