import React from 'react';
import { TaskModel } from 'api/model/task';
import TaskCard from 'components/molecules/TaskCard';

type Props = {
  status: {
    id: string;
    name: string;
    taskList: TaskModel[];
  };
  handleTaskCardClick: (taskId: number) => void;
  selectedTaskId?: number;
};

export default function ConditionalSortedTasks(props: Props) {
  const { status, handleTaskCardClick, selectedTaskId } = props;

  return (
    <>
      {status.taskList.map((task) => (
        <div key={task.id}>
          <TaskCard
            task={task}
            onClick={() => handleTaskCardClick(task.id)}
            selectedTaskId={selectedTaskId}
            isSortable={false}
          />
        </div>
      ))}
    </>
  );
}
