import React, { ReactNode } from 'react';
import { DesignStepModel } from '../../../api/model/designStep';
import { HouseholdModel } from '../../../api/model/household';
import { HouseholdFlagModel } from '../../../api/model/agenda';
import A from '../../atoms/A';
import HouseholdFlag from '../../atoms/HouseholdFlag';
import PageTitle from 'components/atoms/PageTitle';

const defaultProps = {
  designSteps: [],
};

type Props = {
  children?: ReactNode;
  designSteps: DesignStepModel[];
  households?: HouseholdModel[];
} & typeof defaultProps;

export default function Articles(props: Props) {
  const { designSteps, households } = props;

  const handleClassNames = (householdFlags: HouseholdFlagModel[]) => {
    // 【多世帯ではない場合】 ignored または 赤flagが表示される場合に`-flag-single`クラスがつく。
    if (households?.length === 1 && householdFlags.length === 1)
      return '-flag-single';
    // 【多世帯の場合】 ignored または flagがひとつでも表示される場合に`-flag-bg`クラスがつく。
    if (householdFlags.length !== 0) return '-flag-bg';
    return '';
  };

  return (
    <>
      <PageTitle>コラム一覧</PageTitle>
      <div id="pageTop" className="container -with-bar">
        <div className="Media__root-agenda">
          <div className="Media__box Media__box-agenda">
            <div className="Media__box-inner-agenda">
              {designSteps.map((designStep) => (
                <div key={designStep.design_step_name}>
                  <h2>{designStep.design_step_name.replace('(ver3)', '')}</h2>
                  <ul className="Media__list-agenda">
                    {designStep.agendas.map((agenda) => {
                      return (
                        <li
                          key={agenda.name}
                          className={`${handleClassNames(
                            agenda.household_flags
                          )}`}
                        >
                          {households &&
                            households?.length > 1 &&
                            agenda.household_flags.map((householdFlag) => (
                              <HouseholdFlag
                                colorCode={householdFlag.name}
                                key={agenda.name}
                              />
                            ))}
                          <A to={`/agendas/${agenda.id}`}>
                            <img src={agenda.image} alt="" />
                            <span className="text-name">
                              {agenda.name}
                              <span className="text-number">
                                {agenda.ignored
                                  ? '0'
                                  : agenda.completed_articles_count}
                                /{agenda.total_articles_count}
                              </span>
                            </span>
                          </A>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Articles.defaultProps = defaultProps;
