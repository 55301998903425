import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../App/store';
import { FurnitureModel } from 'api/model/furniture';
import { serializeError } from 'serialize-error';
import {
  fetch,
  selectedFetch,
  create,
  update,
  ImageParams,
  destroy,
} from '../../api/furniture';

type FurnitureLoaded = {
  furnitures: FurnitureModel[];
  designApprovalCompletion: boolean;
};

type CreateFurnitureLoaded = {
  furniture: FurnitureModel;
};

type UpdateFurnitureLoaded = {
  furniture: FurnitureModel;
};

type DeleteFurnitureLoaded = {
  furnitures: FurnitureModel[];
};

type FurnitureState = {
  furnitures: FurnitureModel[];
  designApprovalCompletion: boolean;
  loading: boolean;
  error: any;
};

const initialState: FurnitureState = {
  furnitures: [],
  designApprovalCompletion: false,
  loading: false,
  error: null,
};

const furniture = createSlice({
  name: 'furniture',
  initialState,
  reducers: {
    fetchFurnituresStart(state) {
      state.loading = true;
    },
    fetchFurnituresSuccess(state, action: PayloadAction<FurnitureLoaded>) {
      const { furnitures, designApprovalCompletion } = action.payload;
      state.furnitures = furnitures;
      state.designApprovalCompletion = designApprovalCompletion;
      state.error = null;
      state.loading = false;
    },
    fetchFurnituresFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    createFurnitureStart(state) {
      state.loading = true;
    },
    createFurnitureSuccess(
      state,
      action: PayloadAction<CreateFurnitureLoaded>
    ) {
      const { furniture } = action.payload;
      state.furnitures = [furniture, ...state.furnitures];
      state.loading = false;
      state.error = null;
    },
    createFurnitureFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    updateFurnitureStart(state) {
      state.loading = true;
    },
    updateFurnitureSuccess(
      state,
      action: PayloadAction<UpdateFurnitureLoaded>
    ) {
      const { furniture } = action.payload;
      const updateFurniture = state.furnitures.findIndex(
        (currentFurniture) => currentFurniture.id === Number(furniture.id)
      );
      if (updateFurniture !== -1) {
        state.furnitures.splice(updateFurniture, 1);
        state.furnitures = [furniture, ...state.furnitures];
      }
      state.loading = false;
      state.error = null;
    },
    updateFurnitureFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    deleteFurnituresStart(state) {
      state.loading = true;
    },
    deleteFurnituresSuccess(
      state,
      action: PayloadAction<DeleteFurnitureLoaded>
    ) {
      const { furnitures } = action.payload;
      state.furnitures = furnitures;
      state.error = null;
      state.loading = false;
    },
    deleteFurnituresFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchFurnituresSuccess,
  fetchFurnituresStart,
  fetchFurnituresFailure,
  createFurnitureSuccess,
  createFurnitureStart,
  createFurnitureFailure,
  updateFurnitureSuccess,
  updateFurnitureStart,
  updateFurnitureFailure,
  deleteFurnituresSuccess,
  deleteFurnituresStart,
  deleteFurnituresFailure,
  resetError,
} = furniture.actions;
export default furniture.reducer;

export const fetchFurnitures = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchFurnituresStart());
    const res = await fetch();
    dispatch(
      fetchFurnituresSuccess({
        furnitures: res.data.data.furnitures,
        designApprovalCompletion: res.data.data.design_approval_completion,
      })
    );
  } catch (err: any) {
    dispatch(fetchFurnituresFailure(err));
    throw err;
  }
};

export const fetchSelectedFurnitures =
  (householdId: number | null, isDefaultHousehold: boolean | null): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchFurnituresStart());
      const res = await selectedFetch({
        householdId: householdId,
        isDefaultHousehold: isDefaultHousehold,
      });
      dispatch(
        fetchFurnituresSuccess({
          furnitures: res.data.data.furnitures,
          designApprovalCompletion: res.data.data.design_approval_completion,
        })
      );
    } catch (err: any) {
      dispatch(fetchFurnituresFailure(err));
      throw err;
    }
  };

export const createFurniture =
  (
    // number, boolean型もstringで送られてくるためstring指定
    name: string,
    width: string,
    depth: string,
    height: string,
    place: string,
    purchase: string,
    memo: string,
    files: ImageParams[],
    householdId: number | null
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createFurnitureStart());
      const res = await create({
        name: name,
        width: width,
        depth: depth,
        height: height,
        place: place,
        purchase: purchase,
        memo: memo,
        files: files,
        householdId: householdId,
      });
      dispatch(
        createFurnitureSuccess({
          furniture: res.data.data.furniture,
        })
      );
    } catch (err: any) {
      dispatch(createFurnitureFailure(err));
      throw err;
    }
  };

export const updateFurniture =
  (
    // number, boolean型もstringで送られてくるためstring指定
    furnitureId: number,
    name: string,
    width: string,
    depth: string,
    height: string,
    place: string,
    purchase: string,
    memo: string,
    files: ImageParams[],
    householdId: number | null
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateFurnitureStart());
      const res = await update({
        furnitureId: furnitureId,
        name: name,
        width: width,
        depth: depth,
        height: height,
        place: place,
        purchase: purchase,
        memo: memo,
        files: files,
        householdId: householdId,
      });
      dispatch(
        updateFurnitureSuccess({
          furniture: res.data.data.furniture,
        })
      );
    } catch (err: any) {
      dispatch(updateFurnitureFailure(err));
      throw err;
    }
  };

export const deleteFurniture =
  (furnitureId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteFurnituresStart());
      const res = await destroy({ furnitureId });
      dispatch(
        deleteFurnituresSuccess({
          furnitures: res.data.data.furnitures,
        })
      );
    } catch (err: any) {
      dispatch(deleteFurnituresFailure(err));
      throw err;
    }
  };
