import { serializeError } from 'serialize-error';
import { AppThunk } from '../../App/store';
import { fetch } from '../../api/material';
import { MaterialModel } from '../../api/model/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MaterialLoaded = {
  material: MaterialModel;
};

type MaterialState = {
  material?: MaterialModel | null;
  loading: boolean;
  fetching: boolean;
  error: any;
};

const initialState: MaterialState = {
  material: null,
  loading: false,
  fetching: true,
  error: null,
};

const material = createSlice({
  name: 'material',
  initialState,
  reducers: {
    fetchMaterialStart(state) {
      state.loading = true;
      state.fetching = true;
    },
    fetchMaterialSuccess(state, action: PayloadAction<MaterialLoaded>) {
      const { material } = action.payload;
      state.material = material;
      state.loading = false;
      state.fetching = false;
      state.error = null;
    },
    fetchMaterialFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
      state.fetching = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchMaterialSuccess,
  fetchMaterialStart,
  fetchMaterialFailure,
  resetError,
} = material.actions;
export default material.reducer;

export const fetchMaterial = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchMaterialStart());
    const res = await fetch();
    setTimeout(
      () =>
        dispatch(
          fetchMaterialSuccess({
            material: res.data.data.material,
          })
        ),
      1000
    );
  } catch (err: any) {
    dispatch(fetchMaterialFailure(err));
    throw err;
  }
};
