import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';

const defaultProps = {
  title: '',
  titleClass: 'Bar__title',
};

type Props = {
  children?: ReactNode;
  title?: string;
  titleClass?: string;
};

export default function Nav(props: Props) {
  const navigate = useNavigate();
  const { children, title, titleClass } = props;

  return (
    <>
      <div className={`Bar -shadow u-flex-04`}>
        <span className="Bar__btn-return" onClick={() => navigate(-1)} />
        <h1 className={titleClass}>{children || title}</h1>
      </div>
    </>
  );
}

Nav.defaultProps = defaultProps;
