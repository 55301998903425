import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetch } from '../../api/designStep';
import { AppThunk } from '../../App/store';
import { DesignStepModel } from '../../api/model/designStep';
import { serializeError } from 'serialize-error';

type DesignStepLoaded = {
  designSteps: DesignStepModel[];
};

type DesignStepState = {
  designSteps: DesignStepModel[];
  loading: boolean;
  error: any;
};

const initialState: DesignStepState = {
  designSteps: [],
  loading: false,
  error: null,
};

const designStep = createSlice({
  name: 'designStep',
  initialState,
  reducers: {
    fetchDesignStepsStart(state) {
      state.loading = true;
    },
    fetchDesignStepsSuccess(state, action: PayloadAction<DesignStepLoaded>) {
      const { designSteps } = action.payload;
      state.designSteps = designSteps;
      state.loading = false;
      state.error = null;
    },
    fetchDesignStepsFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchDesignStepsSuccess,
  fetchDesignStepsStart,
  fetchDesignStepsFailure,
  resetError,
} = designStep.actions;
export default designStep.reducer;

export const fetchDesignSteps = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchDesignStepsStart());
    const res = await fetch();
    dispatch(
      fetchDesignStepsSuccess({
        designSteps: res.data.data.design_steps,
      })
    );
  } catch (err: any) {
    dispatch(fetchDesignStepsFailure(err));
    throw err;
  }
};
