import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import Loading from '../../../components/atoms/Loading';

type Props = {
  children?: ReactNode;
};

export default function HeaderWithRedux(props: Props) {
  const loading = useSelector((state: RootState) => {
    let _loading = false;
    Object.keys(state).forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (state[key] && state[key].loading) {
        _loading = true;
      }
    });
    return _loading;
  });

  return (
    <>
      <Loading loading={loading} />
    </>
  );
}
