import React, { useState, ReactNode } from 'react';
import { FurnitureModel } from 'api/model/furniture';
import { FormData } from 'components/organisms/FurnitureModal';
import AlertDialog from '../../atoms/AlertDialog';
import PageTitle from '../../atoms/PageTitle';
import MediaQuery from 'react-responsive';
import FurnitureModal from '../../organisms/FurnitureModal';
import { HouseholdModel } from '../../../api/model/household';

const defaultProps = {
  loading: false,
};

type Props = {
  children?: ReactNode;
  furnitures: FurnitureModel[];
  households?: HouseholdModel[];
  onDelete: (
    furnitureId: number,
    householdId: number | null,
    isDefaultHousehold: boolean | null
  ) => void;
  onSubmitFurniture: (
    params: FormData,
    files: object[],
    householdId: number | null
  ) => void;
  onUpdateFurniture: (
    params: FormData,
    files: object[],
    householdId: number | null
  ) => void;
  loading: boolean;
  slideOpen: boolean;
  designApprovalCompletion: boolean;
  handedOver4monthsAgo: boolean;
  handleFetch: (
    householdId: number | null,
    isDefaultHousehold: boolean | null
  ) => void;
} & typeof defaultProps;

export default function Furniture(props: Props) {
  const {
    furnitures,
    households,
    onSubmitFurniture,
    onDelete,
    onUpdateFurniture,
    loading,
    slideOpen,
    designApprovalCompletion,
    handedOver4monthsAgo,
    handleFetch,
  } = props;

  const [openSelectedFurnitureId, setOpenSelectedFurnitureId] = useState(0);
  const [selectedFurnitureId, setSelectedFurnitureId] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [alertOpen, setOpenAlert] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [openNoEditAlert, setOpenNoEditAlert] = useState(false);
  const [targetFurniture, setTargetFurniture] = useState<FurnitureModel | null>(
    null
  );
  const [currentTab, setCurrentTab] = useState('/construction');
  const onClick = (id: number, path: string) => {
    path === '/construction'
      ? handleFetch(null, true)
      : handleFetch(Number(id), null);
    setCurrentTab(path);
  };

  const handleClassNames = (furniture: FurnitureModel) => {
    const purchaseClassName = furniture.purchase ? '-purchase' : '';
    const blankImageClassName =
      furniture.images.length === 0 ? 'item-image-blank' : '';
    return `${purchaseClassName} ${blankImageClassName}`;
  };

  const handleFurnitureImageMenu = (furniture: FurnitureModel) => {
    openSelectedFurnitureId === furniture.id
      ? setOpenSelectedFurnitureId(0)
      : setOpenSelectedFurnitureId(furniture.id);
  };

  const handleSelectedFurniture = (furniture: FurnitureModel) => {
    if (openSelectedFurnitureId !== furniture.id) {
      setSelectedFurnitureId(furniture.id);
    }
  };

  const getTargetFurniture = (furnitureId: number): any => {
    const furniture = furnitures.filter((f) => f.id === furnitureId)[0];
    return setTargetFurniture(furniture);
  };

  async function handlePost(params: FormData, files: object[]) {
    const householdId =
      currentTab.split('/')[1] === 'construction'
        ? null
        : Number(currentTab.split('/')[1]);

    if (params.furnitureId) {
      try {
        if (onUpdateFurniture) {
          await onUpdateFurniture(params, files, householdId);
        }
      } catch (error) {
        throw error;
      }
    } else {
      try {
        if (onSubmitFurniture) {
          await onSubmitFurniture(params, files, householdId);
        }
      } catch (error) {
        throw error;
      }
    }
  }

  async function handleDelete() {
    if (currentTab === '/construction') {
      onDelete(selectedFurnitureId, null, true);
    } else {
      const householdId = currentTab.split('/')[1];
      onDelete(selectedFurnitureId, Number(householdId), null);
    }
  }

  const handleCloseAlert = () => {
    setOpenNoEditAlert(false);
  };

  const handleAlertDescription = () => {
    return handedOver4monthsAgo
      ? 'お引渡しから4ヶ月が経過しているため、編集はできません。'
      : '実施設計完了承諾が完了しているため、編集はできません。';
  };

  const renderDesignApprovalCompletedAlert = () => {
    return (
      <AlertDialog
        onAgree={() => {
          handleCloseAlert();
        }}
        onClose={() => {
          handleCloseAlert();
        }}
        open={openNoEditAlert}
        title={'編集不可'}
        description={handleAlertDescription()}
        singleButton
      />
    );
  };

  const renderTabList = () => {
    return (
      <ul className="Tab__list">
        {households &&
          households.map((household, index) => {
            const path = index === 0 ? '/construction' : `/${household.id}`;
            return (
              <li
                key={index}
                className={`${currentTab === path && '-current'}`}
                onClick={() => onClick(household.id, path)}
              >
                <span className="Tab__name">{household.name}</span>
              </li>
            );
          })}
      </ul>
    );
  };

  const renderHouseholdsTab = () => {
    return (
      <>
        <MediaQuery query="(max-width: 768px)">
          <div
            className="Tab -furniture"
            style={{ left: slideOpen ? '33.0rem' : '0' }}
          >
            {renderTabList()}
          </div>
        </MediaQuery>
        <MediaQuery query="(min-width: 769px)">
          <div className="Tab -furniture">{renderTabList()}</div>
        </MediaQuery>
      </>
    );
  };

  return (
    <>
      {renderDesignApprovalCompletedAlert()}
      <PageTitle>家具家電リスト</PageTitle>
      {openModal && (
        <FurnitureModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          onSubmit={(form, files) => handlePost(form, files)}
          furniture={targetFurniture}
          loading={loading}
          viewMode={viewMode}
          setViewMode={setViewMode}
          setOpenNoEditAlert={setOpenNoEditAlert}
          designApprovalCompletion={designApprovalCompletion}
          handedOver4monthsAgo={handedOver4monthsAgo}
        />
      )}
      <div id="pageTop" className="container">
        <div className="Furniture">
          <div className="Announce__note">
            <p>
              新居に必要な家具・家電の入力をお願いします。
              <br className="u-display-pc" />
              搬入経路や構造補強、スイッチの位置などの参考にするため、
              <br className="u-display-pc" />
              家具家電の他、ピアノ、金庫、絵、水槽等も入力をお願いします。
            </p>
          </div>
          {households && households.length > 1 && renderHouseholdsTab()}
          <div className="Furniture__inner">
            <ul className="Furniture__list">
              {furnitures && furnitures.length !== 50 && (
                <li
                  className="item-add"
                  onClick={() => {
                    if (designApprovalCompletion || handedOver4monthsAgo) {
                      setOpenNoEditAlert(true);
                    } else {
                      getTargetFurniture(0);
                      setViewMode(false);
                      setOpenModal(true);
                    }
                  }}
                />
              )}
              {furnitures &&
                furnitures.map((furniture) => (
                  <li
                    className={handleClassNames(furniture)}
                    key={furniture.id}
                  >
                    <span className="Furniture__item-image">
                      <span
                        onClick={() => {
                          getTargetFurniture(furniture.id);
                          setViewMode(true);
                          setOpenModal(true);
                        }}
                      >
                        <img
                          src={
                            furniture.images[0]
                              ? furniture.images[0].url
                              : '/assets/img/furniture/blank_image.svg'
                          }
                          alt="家具家電画像"
                        />
                      </span>
                    </span>
                    <span
                      className="Furniture__item-menu"
                      onClick={() => {
                        if (designApprovalCompletion || handedOver4monthsAgo) {
                          setOpenNoEditAlert(true);
                        } else {
                          handleFurnitureImageMenu(furniture);
                          handleSelectedFurniture(furniture);
                        }
                      }}
                    >
                      <img
                        src="/assets/img/common/icon/setting_menu.svg"
                        alt="ファイルメニュー"
                      />
                      <span
                        className={`Furniture__item-image-balloon ${
                          openSelectedFurnitureId === furniture.id && '-show'
                        }`}
                      >
                        <span
                          className="-edit"
                          onClick={() => {
                            setViewMode(false);
                            setOpenModal(true);
                            getTargetFurniture(furniture.id);
                          }}
                        >
                          編集
                        </span>
                        {!furniture.is_default && (
                          <span
                            className="-delete"
                            onClick={() => setOpenAlert(true)}
                          >
                            削除
                          </span>
                        )}
                      </span>
                    </span>
                    <p className="text-name">{furniture.name}</p>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <AlertDialog
        title="家具家電リストの削除"
        description="このリストを削除しますか？"
        open={alertOpen}
        onClose={() => {
          setOpenAlert(false);
          setOpenSelectedFurnitureId(0);
        }}
        onAgree={() => handleDelete()}
      />
    </>
  );
}

Furniture.defaultProps = defaultProps;
