import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './AlertDialog.scss';

const defaultProps = {
  open: false,
  title: '',
  description: '',
  singleButton: false,
};

type Props = {
  open: boolean;
  onClose: (isComplete: boolean) => void;
  onAgree: () => void;
} & typeof defaultProps;

export default function AlertDialog(props: Props) {
  const { open, onClose, onAgree, title, description, singleButton } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleAgree = () => {
    onAgree();
    onClose(false);
  };

  return (
    <div>
      <Dialog
        maxWidth="xl"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="Dialog__title">
          {title}
        </DialogTitle>
        <DialogContent className="Dialog__content">
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!singleButton && <Button onClick={handleCancel}>キャンセル</Button>}
          <Button onClick={handleAgree} color="primary">
            {singleButton ? '確認しました' : 'はい'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.defaultProps = defaultProps;
