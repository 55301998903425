import { apiClient } from './client';
import { AppointmentModel } from './model/appointment';
import { MilestoneModel } from './model/milestone';

export type FetchParams = {
  isHome: boolean;
};

export type FetchRes = {
  data: {
    appointments: AppointmentModel[];
    milestone: MilestoneModel;
    hasUsingHouseholds: boolean;
  };
};

export async function fetch(params: FetchParams) {
  const query = params.isHome ? '?page_type=home' : '';
  return apiClient.get<FetchRes>(`/appointments${query}`);
}

export type GetParams = {
  appointmentId: number;
};

export type GetRes = {
  data: {
    appointment: AppointmentModel;
    milestone: MilestoneModel;
  };
};

export async function get(params: GetParams) {
  return apiClient.get<GetRes>(`/appointments/${params.appointmentId}`);
}
