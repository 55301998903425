import React, { ReactNode } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';

export type ModalBaseProps = {
  children: ReactNode;
  open: boolean;
  onClose: (reason: string) => void;
} & DialogProps;

export default function ModalBase(props: ModalBaseProps) {
  const { onClose, open, children, ...rest } = props;

  const handleClose = (reason: string) => {
    onClose(reason);
  };

  return (
    <Dialog
      onClose={(event, reason) => handleClose(reason)}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="xl"
      {...rest}
    >
      {children}
    </Dialog>
  );
}
