import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../App/store';
import { TaskModel } from 'api/model/task';
import { serializeError } from 'serialize-error';
import {
  fetch,
  fetchDetail,
  fetchHistory,
  create,
  update,
  destroy,
  sort,
} from '../../api/task';

type TasksLoaded = {
  tasks: TaskModel[];
};

type TaskLoaded = {
  task: TaskModel;
};

type CreateTaskLoaded = {
  task: TaskModel;
};

type UpdateTaskLoaded = {
  tasks: TaskModel[];
};

type SortTaskLoaded = {
  task: TaskModel;
};

type DeleteTaskLoaded = {
  tasks: TaskModel[];
};

type TaskState = {
  tasks: TaskModel[];
  task?: TaskModel;
  histories: TaskModel[];
  loading: boolean;
  error: any;
};

const initialState: TaskState = {
  tasks: [],
  task: undefined,
  histories: [],
  loading: false,
  error: null,
};

const task = createSlice({
  name: 'task',
  initialState,
  reducers: {
    fetchTaskStart(state) {
      state.loading = true;
    },
    fetchTaskSuccess(state, action: PayloadAction<TasksLoaded>) {
      const { tasks } = action.payload;
      state.tasks = tasks;
      state.error = null;
      state.loading = false;
    },
    fetchTaskSuccessWithoutLoading(state, action: PayloadAction<TasksLoaded>) {
      const { tasks } = action.payload;
      state.tasks = tasks;
      state.error = null;
    },
    fetchTaskFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    fetchTaskDetailStart(state) {
      state.loading = true;
    },
    fetchTaskDetailSuccess(state, action: PayloadAction<TaskLoaded>) {
      const { task } = action.payload;
      state.task = task;
      state.error = null;
      state.loading = false;
    },
    fetchTaskDetailFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    fetchTaskHistoryStart(state) {
      state.loading = true;
    },
    fetchTaskHistorySuccess(state, action: PayloadAction<TasksLoaded>) {
      const { tasks } = action.payload;
      state.histories = tasks;
      state.error = null;
      state.loading = false;
    },
    fetchTaskHistoryFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    createTaskStart(state) {
      state.loading = true;
    },
    createTaskSuccess(state, action: PayloadAction<CreateTaskLoaded>) {
      const { task } = action.payload;
      state.tasks = [task, ...state.tasks];
      state.loading = false;
      state.error = null;
    },
    createTaskFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    updateTaskStart(state) {
      state.loading = true;
    },
    updateTaskSuccess(state, action: PayloadAction<UpdateTaskLoaded>) {
      const { tasks } = action.payload;
      state.tasks = tasks;
      state.loading = false;
      state.error = null;
    },
    updateTaskFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    sortTaskSuccess(state) {
      state.error = null;
    },
    sortTaskFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    deleteTaskStart(state) {
      state.loading = true;
    },
    deleteTaskSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    deleteTaskFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchTaskStart,
  fetchTaskSuccess,
  fetchTaskSuccessWithoutLoading,
  fetchTaskFailure,
  fetchTaskDetailStart,
  fetchTaskDetailSuccess,
  fetchTaskDetailFailure,
  fetchTaskHistoryStart,
  fetchTaskHistorySuccess,
  fetchTaskHistoryFailure,
  createTaskStart,
  createTaskSuccess,
  createTaskFailure,
  updateTaskStart,
  updateTaskSuccess,
  updateTaskFailure,
  sortTaskSuccess,
  sortTaskFailure,
  deleteTaskStart,
  deleteTaskSuccess,
  deleteTaskFailure,
  resetError,
} = task.actions;
export default task.reducer;

export const fetchTasks =
  (
    executableType: string,
    executableId: number,
    needLoading: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      needLoading && dispatch(fetchTaskStart());
      const res = await fetch({
        executableType: executableType,
        executableId: executableId,
      });
      needLoading
        ? dispatch(
            fetchTaskSuccess({
              tasks: res.data.data.tasks,
            })
          )
        : dispatch(
            fetchTaskSuccessWithoutLoading({
              tasks: res.data.data.tasks,
            })
          );
    } catch (err: any) {
      dispatch(fetchTaskFailure(err));
      throw err;
    }
  };

export const fetchTaskDetail =
  (taskId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchTaskDetailStart());
      const res = await fetchDetail({
        taskId: taskId,
      });
      dispatch(
        fetchTaskDetailSuccess({
          task: res.data.data.task,
        })
      );
      return res.data.data.task;
    } catch (err: any) {
      dispatch(fetchTaskHistoryFailure(err));
      throw err;
    }
  };

export const fetchTaskHistory =
  (taskId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchTaskHistoryStart());
      const res = await fetchHistory({
        taskId: taskId,
      });
      dispatch(
        fetchTaskHistorySuccess({
          tasks: res.data.data.tasks,
        })
      );
      return res.data.data.tasks;
    } catch (err: any) {
      dispatch(fetchTaskHistoryFailure(err));
      throw err;
    }
  };

export const createTask =
  (title: string, executableType: string, executableId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createTaskStart());
      const res = await create({
        title: title,
        executableType: executableType,
        executableId: executableId,
      });
      dispatch(
        createTaskSuccess({
          task: res.data.data.task,
        })
      );
    } catch (err: any) {
      dispatch(createTaskFailure(err));
      throw err;
    }
  };

export const updateTask =
  (
    taskId: number,
    title: string,
    detail: string,
    dueDate: string | null,
    priority: string,
    progressStatus: string,
    sharedStatus: string,
    // colorId: number,今後追加予定
    trashed: boolean,
    executableType: string,
    executableId: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateTaskStart());
      const res = await update({
        taskId: taskId,
        title: title,
        detail: detail,
        dueDate: dueDate,
        priority: priority,
        sharedStatus: sharedStatus,
        progressStatus: progressStatus,
        // colorId: colorId, 今後追加予定
        trashed: trashed,
        executableType: executableType,
        executableId: executableId,
      });
      dispatch(
        updateTaskSuccess({
          tasks: res.data.data.tasks,
        })
      );
    } catch (err: any) {
      dispatch(updateTaskFailure(err));
      throw err;
    }
  };

export const sortTask =
  (taskId: number, moveTo: number): AppThunk =>
  async (dispatch) => {
    try {
      // state.loadingの値を変更する必要がないためsortTaskstartなし
      await sort({ taskId, moveTo });
      dispatch(sortTaskSuccess());
    } catch (err: any) {
      dispatch(sortTaskFailure(err));
      throw err;
    }
  };

export const deleteTask =
  (taskId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteTaskStart());
      await destroy({ taskId });
      deleteTaskSuccess();
    } catch (err: any) {
      dispatch(deleteTaskFailure(err));
      throw err;
    }
  };
