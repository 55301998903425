import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const defaultProps = {
  value: 0,
  bgColor: '#F9B8B6',
  pgColor: '#FF0000',
  size: '186px',
  labelText: '',
};

type Props = {
  children?: ReactNode;
} & typeof defaultProps;

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-block',
  },
  background: {
    color: '#333333',
    position: 'relative',
  },
  main: {
    color: 'red',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  progress: {
    color: '#000000',
    position: 'relative',
  },
  circle: {
    strokeWidth: '1px',
  },
  contents: {
    color: '#000',
    fontSize: '15px',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    position: 'absolute',
    textAlign: 'center',
  },
  preText: {
    fontSize: '12px',
  },
  valueWrapper: {
    marginTop: '6px',
  },
  valueText: {
    fontSize: '32px',
  },
});

export default function Progress(props: Props) {
  const { value, pgColor, bgColor, size, labelText } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div style={{ width: size }} className={classes.progress}>
        <div style={{ color: bgColor }} className={classes.background}>
          <CircularProgress
            classes={{
              circle: classes.circle,
            }}
            color="inherit"
            variant="determinate"
            value={100}
            size={size}
          />
        </div>
        <div style={{ color: pgColor }} className={classes.main}>
          <CircularProgress
            classes={{
              circle: classes.circle,
            }}
            color="inherit"
            variant="determinate"
            value={value}
            size={size}
          />
        </div>
        <div className={classes.contents}>
          <div className={classes.preText}>{labelText}</div>
          <div className={classes.valueWrapper}>
            <span className={classes.valueText}>{value}</span>
            <span>%</span>
          </div>
        </div>
      </div>
    </div>
  );
}

Progress.defaultProps = defaultProps;
