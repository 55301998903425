import { withStyles, Theme, Tooltip } from '@material-ui/core';

type Props = {
  backgroundColor?: string;
  color?: string;
  fontSize?: string;
};

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: (props: Props) => ({
    backgroundColor: props.backgroundColor || theme.palette.common.white,
    color: props.color || 'rgba(0, 0, 0, 0.87)',
    fontSize: props.fontSize || '11px',
    boxShadow: theme.shadows[1],
  }),
}))(Tooltip);

export default LightTooltip;
