import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../App/store';
import { fetch } from '../../api/idSheet';
import { idSheetModel } from '../../api/model/idSheet';
import { serializeError } from 'serialize-error';

type IdsheetLoaded = {
  idSheets: idSheetModel[];
};

type IdsheetState = {
  idSheets: idSheetModel[];
  loading: boolean;
  error: any;
};

const initialState: IdsheetState = {
  idSheets: [],
  loading: false,
  error: null,
};

const idSheet = createSlice({
  name: 'idSheet',
  initialState,
  reducers: {
    fetchIdSheetsStart(state) {
      state.loading = true;
    },
    fetchIdSheetsSuccess(state, action: PayloadAction<IdsheetLoaded>) {
      const { idSheets } = action.payload;
      state.idSheets = idSheets;
      state.loading = false;
      state.error = null;
    },
    fetchIdSheetsFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchIdSheetsSuccess,
  fetchIdSheetsStart,
  fetchIdSheetsFailure,
  resetError,
} = idSheet.actions;
export default idSheet.reducer;

export const fetchIdSheets = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchIdSheetsStart());
    const res = await fetch();
    dispatch(
      fetchIdSheetsSuccess({
        idSheets: res.data.data.id_sheets,
      })
    );
  } catch (err: any) {
    dispatch(fetchIdSheetsFailure(err));
    throw err;
  }
};
