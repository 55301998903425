import { apiClient } from './client';
import { MaterialModel } from './model/material';

export type FetchRes = {
  data: {
    material: MaterialModel;
  };
};

export async function fetch() {
  return apiClient.get<FetchRes>(`/material_characteristics`);
}
