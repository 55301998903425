import { serializeError } from 'serialize-error';
import { AppThunk } from '../../App/store';
import { get } from '../../api/agenda';
import { AgendaModel } from '../../api/model/agenda';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AgendaLoaded = {
  agenda: AgendaModel;
};

type AgendaState = {
  agenda: null | AgendaModel;
  loading: boolean;
  fetching: boolean;
  error: any;
};

const initialState: AgendaState = {
  agenda: null,
  loading: false,
  fetching: true,
  error: null,
};

const agenda = createSlice({
  name: 'agenda',
  initialState,
  reducers: {
    getAgendaStart(state) {
      state.loading = true;
      state.fetching = true;
    },
    getAgendaSuccess(state, action: PayloadAction<AgendaLoaded>) {
      const { agenda } = action.payload;
      state.agenda = agenda;
      state.loading = false;
      state.fetching = false;
      state.error = null;
    },
    getAgendaFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
      state.fetching = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  getAgendaSuccess,
  getAgendaStart,
  getAgendaFailure,
  resetError,
} = agenda.actions;
export default agenda.reducer;

export const getAgenda =
  (agendaId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAgendaStart());
      const res = await get({ agendaId: agendaId });
      setTimeout(
        () =>
          dispatch(
            getAgendaSuccess({
              agenda: res.data.data.agenda,
            })
          ),
        1000
      );
    } catch (err: any) {
      dispatch(getAgendaFailure(err));
      throw err;
    }
  };
